import React, { useEffect, useState } from "react";
import css from "../../style/orderSummary.module.css";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getSingleBooking } from "../../store/actions/bookingActions";
import * as Sentry from "@sentry/react";

const OrderList = ({ name, value, index }) => {
  return (
    <div
      className={css?.itemMainDiv}
      style={{ backgroundColor: index % 2 ? "white" : "#E5E4E2" }}
    >
      <div className={css?.itemKey}>{name}</div>
      <div className={css?.itemValue}>{value}</div>
    </div>
  );
};
const OrderSummary = () => {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();

  const [Summary, setSummary] = useState([]);

  const { bookingInfo } = useSelector((state) => state.bookingReducer);

  useEffect(() => {
    try {
      dispatch(getSingleBooking(id));
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, []);

  const handleAcceptBooking = () => {
    try {
      history.push("/moveCompleted");
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };
  const handleRejectBooking = () => {
    try {
      history.push("/rejectSummary");
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  useEffect(() => {
    try {
      if (bookingInfo?.bookingTotalModel) {
        const total = bookingInfo.bookingTotalModel;
        const tempSummary = [];
        if (total.base !== null && total.base !== undefined)
          tempSummary.push({ name: "Base fare", value: `$${total.base}` });
        if (total.totalDistance !== null && total.totalDistance !== undefined)
          tempSummary.push({ name: "Distance", value: total.totalDistance });
        if (total.subtotal !== null && total.subtotal !== undefined)
          tempSummary.push({
            name: "Subtotal Amount",
            value: `$${total.subtotal}`,
          });
        if (total.gstandPst !== null && total.gstandPst !== undefined)
          tempSummary.push({
            name: `GST (${
              total.totalTaxGstpstrate ? total.totalTaxGstpstrate : 5
            }%)`,
            value: total.gstandPst,
          });
        if (total.totalCharge !== null && total.totalCharge !== undefined)
          tempSummary.push({ name: "Total Charge", value: total.totalCharge });
        setSummary(tempSummary);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [bookingInfo]);

  return (
    <div className={css?.orderSummaryMainDiv}>
      {Summary?.length ? (
        <div className={css?.cardMainDiv}>
          <div className={css?.headingMainDiv}>Order Summary</div>
          <div className={css?.BookingNoDiv}>Booking # {bookingInfo?.id}</div>
          {Summary.map((properties, index) => (
            <OrderList {...properties} index={index} />
          ))}
          <div className={css?.buttonsDiv}>
            <div
              className={css?.acceptBtn}
              onClick={() => handleAcceptBooking()}
            >
              Accept
            </div>
            <div
              className={css?.rejectBtn}
              onClick={() => handleRejectBooking()}
            >
              Reject
            </div>
          </div>{" "}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default OrderSummary;
