import { FEED_BACK_REQUEST, FEED_BACK_SUCCESS, FEED_BACK_FAIL } from "../constants/feedBackConstants";  

export const feedBackReducer = (state = {},action) =>{
    const {type,payload} = action;
    switch (type) {
        case FEED_BACK_REQUEST:
        return {
            ...state,
            feedBackLoading: true,
            feedback:null,
            feedBackError: "",
        };
        case FEED_BACK_SUCCESS:
        return {
            ...state,
            feedBackLoading: false,
            feedback:payload,
            feedBackError: "",
        };
        case FEED_BACK_FAIL:
        return {
            ...state,
            feedBackLoading: false,
            feedback:null,
            feedBackError: payload,
        };
        default:
        return state;
    }
    }