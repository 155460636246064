import React from "react";
import Calendar from "react-calendar";
import css from "../../style/booking.module.css";
import "react-calendar/dist/Calendar.css";
import * as Sentry from "@sentry/react";

const Calander = ({ handleUpdateBookingObject, deliveryDate }) => {
  return (
    <div className={css?.calanderMainDiv}>
      <div
        className={css?.Level1Heading}
        style={{ color: "var(--primaryDark)", width: "100%" }}
      >
        Schedule your pickup time
      </div>
      <div style={{ width: "100%" }}>
        <Calendar
          value={deliveryDate ? new Date(deliveryDate) : new Date()}
          activeStartDate={new Date()}
          onChange={(date) => {
            try {
              handleUpdateBookingObject(
                "deliveryDate",
                date.toString()?.split(" GMT")[0],
                "10"
              );
            } catch (error) {
              Sentry.captureException(error);
              console.log(error);
            }
          }}
          className={css?.calanderConponent}
          minDate={new Date()}
        />
      </div>
    </div>
  );
};

export default Calander;
