import React, { useState, useEffect } from "react";
import css from "../../style/signupStyles.module.css";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signup } from "../../store/actions/loginActions";
import ModalComponent from "../../components/Modals/Modal";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import * as Sentry from "@sentry/react";
import { handleValidatePhoneNumber } from "../../Utilities/general";

function Register() {
  // state to restrict multipel clicks
  const [buttonClicked, setButtonClicked] = useState(false);
  // state to display errors
  const [error, seterror] = useState("");
  //history for routing
  const history = useHistory();
  // dispatch actions
  const dispatch = useDispatch();
  //firstName
  const [firstName, setfirstName] = useState("");
  //last name
  const [lastName, setlastName] = useState("");
  //email
  const [email, setemail] = useState("");
  //phoneNo
  const [phoneNo, setphoneNo] = useState("");
  //password
  const [password, setpassword] = useState("");
  const [passwordStrength, setpasswordStrength] = useState(0);
  //confirmPass
  const [confirmPassword, setconfirmPassword] = useState("");

  // show password toggle
  const [showPassword, setShowPassword] = useState("password");

  // show password toggle
  const [showConfirmPassword, setShowConfirmPassword] = useState("password");
  // custom alert state
  const [modalFields, setModalFields] = useState({
    open: false,
    text: "",
    btnText1: "Ok",
    closeFunc: () => {
      setModalFields({ ...modalFields, open: false });
    },
  });

  useEffect(() => {
    try {
      let makeBtnClickable;

      if (buttonClicked) {
        makeBtnClickable = setTimeout(() => {
          setButtonClicked(false);
        }, 10000);
      }
      return () => clearTimeout(makeBtnClickable);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [buttonClicked]);
  // useEffect to show error modal
  useEffect(() => {
    try {
      if (error) {
        setModalFields({
          ...modalFields,
          open: true,
          text: error,
        });
        seterror("");
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [error]);

  // check password strength
  const handleSetPassword = (e) => {
    try {
      let strengthLevel = 0;
      const passArray = [...e];
      setpassword(e);
      if (e !== 0) {
        const hasLowerCase = passArray?.filter(
          (letter) => letter >= "a" && letter <= "z"
        );
        const hasUpperCase = passArray?.filter(
          (letter) => letter >= "A" && letter <= "Z"
        );
        const hasNumber = passArray?.filter(
          (letter) => letter >= "0" && letter <= "9"
        );
        const hasSC1 = passArray?.filter(
          (letter) => letter >= "!" && letter <= "/"
        );
        const hasSC2 = passArray?.filter(
          (letter) => letter >= ":" && letter <= "@"
        );
        const hasSC3 = passArray?.filter(
          (letter) => letter >= "[" && letter <= "`"
        );
        const hasSC4 = passArray?.filter(
          (letter) => letter >= "{" && letter <= "~"
        );
        if (hasLowerCase?.length > 0) {
          strengthLevel = strengthLevel + 20;
        }
        if (hasUpperCase?.length > 0) {
          strengthLevel = strengthLevel + 20;
        }
        if (hasNumber?.length > 0) {
          strengthLevel = strengthLevel + 20;
        }
        if (
          hasSC1?.length > 0 ||
          hasSC2?.length > 0 ||
          hasSC3?.length > 0 ||
          hasSC4?.length > 0
        ) {
          strengthLevel = strengthLevel + 20;
        }
        if (e?.length > 5) {
          strengthLevel = strengthLevel + 20;
        }
      }
      setpasswordStrength(strengthLevel);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const toggleShowPassword = () => {
    if (showPassword === "text") setShowPassword("password");
    else setShowPassword("text");
  };

  const toggleShowConfirmPassword = () => {
    if (showConfirmPassword === "text") setShowConfirmPassword("password");
    else setShowConfirmPassword("text");
  };
  function ValidateEmail(mail) {
    try {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return true;
      }
      setModalFields({
        ...modalFields,
        open: true,
        text: "You have entered an invalid email address!",
      });
      return false;
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }

  const handleSignUp = () => {
    try {
      if (
        firstName &&
        lastName &&
        email &&
        phoneNo &&
        password &&
        confirmPassword
      ) {
        if (ValidateEmail(email)) {
          if (passwordStrength === 100) {
            if (password === confirmPassword) {
              if (phoneNo?.length === 12) {
                const signupData = {
                  email: email,
                  firstName: firstName,
                  lastName: lastName,
                  password: password,
                  phone: phoneNo,
                };
                console.log("signup data from signup component", signupData);
                if (!buttonClicked) {
                  setButtonClicked(true);
                  dispatch(signup(signupData, seterror, history));
                }
              } else {
                setModalFields({
                  ...modalFields,
                  open: true,
                  text: "Invalid phone number!",
                });
              }
            } else {
              setModalFields({
                ...modalFields,
                open: true,
                text: "Passwords do not match...",
              });
            }
          } else {
            setModalFields({
              ...modalFields,
              open: true,
              text: "Password must be of at least 6 characters, with both upper and lower chase letters,numbers and special characters.",
            });
          }
        }
      } else {
        setModalFields({
          ...modalFields,
          open: true,
          text: "Please fill all the fields!",
        });
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  return (
    <div className={css?.signupMainDiv}>
      <ModalComponent
        text={modalFields.text}
        openState={modalFields.open}
        closeFunction={() => {
          setModalFields({ ...modalFields, open: false });
        }}
        btnText1={modalFields.btnText1}
        btnClickFunc1={modalFields.closeFunc}
      />
      <div className={css?.mainForm}>
        <div
          className={css?.posterMainHeading}
          style={{ fontSize: 32, fontWeight: 700 }}
        >
          You're almost there!
        </div>
        <div className={css?.posterMainHeading} style={{ fontSize: 22 }}>
          Sign up to get moving.
        </div>
        <input
          value={firstName}
          placeholder="First name"
          onChange={(e) => setfirstName(e.target.value)}
          className={css?.fullInput}
        ></input>
        <input
          value={lastName}
          placeholder="Last name"
          onChange={(e) => setlastName(e.target.value)}
          className={css?.fullInput}
        ></input>
        <input
          value={email}
          placeholder="Email"
          onChange={(e) => setemail(e.target.value)}
          className={css?.fullInput}
        ></input>
        <input
          value={phoneNo}
          placeholder="Phone number"
          onChange={(e) => handleValidatePhoneNumber(e, setphoneNo)}
          className={css?.fullInput}
        ></input>
        <div className={css?.inputBox}>
          <input
            value={password}
            type={showPassword}
            placeholder="Password"
            className={css?.passwordInput}
            onChange={(e) => handleSetPassword(e?.target?.value)}
          ></input>
          {showPassword === "password" ? (
            <BsFillEyeFill
              className={css?.passwordEye}
              onClick={() => toggleShowPassword()}
            />
          ) : (
            <BsFillEyeSlashFill
              className={css?.passwordEye}
              onClick={() => toggleShowPassword()}
            />
          )}
        </div>
        <div className={css?.inputBox}>
          <input
            value={confirmPassword}
            type={showConfirmPassword}
            placeholder="Confirm password"
            className={css?.passwordInput}
            onChange={(e) => setconfirmPassword(e?.target?.value)}
          ></input>
          {showPassword === "password" ? (
            <BsFillEyeFill
              className={css?.passwordEye}
              onClick={() => toggleShowConfirmPassword()}
            />
          ) : (
            <BsFillEyeSlashFill
              className={css?.passwordEye}
              onClick={() => toggleShowConfirmPassword()}
            />
          )}
        </div>

        <div className={css?.signupBtnMainDiv}>
          <button className={css?.signupBtn} onClick={handleSignUp}>
            Sign up
          </button>
        </div>
        <div className={css?.signinRedirect}>
          Already have an account?{" "}
          <span
            className={css?.signinTxt}
            onClick={() => history.push("/Login")}
          >
            Sign in
          </span>
        </div>
      </div>
    </div>
  );
}

export default Register;
