import React, { useState } from "react";
import css from "../../style/rejectSummary.module.css";
import { useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";

const RejectSummary = () => {
  const [TNC, setTNC] = useState(false);
  const handleCompleteMove = () => {
    try {
      if (TNC) {
      } else alert("Please accept the terms and conditions and privacy policy");
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };
  return (
    <div className={css?.mainDiv}>
      <div className={css?.cardMainDiv}>
        <div className={css?.headingMainDiv}>Opps!</div>
        <div className={css?.subHeading}>You selected reject</div>
        <div className={css?.generalText}>
          We couldn't complete your move. Please speak to out movers if there is
          an issue with the order summary or make sure that your payment method
          is correct.
        </div>
        <div className={css?.buttonGroup}>
          <div className={css?.Btn1Style} onCLick={() => handleCompleteMove()}>
            Show payment method
          </div>
          <div className={css?.Btn2Style} onCLick={() => handleCompleteMove()}>
            Return to homepage
          </div>
        </div>
      </div>
    </div>
  );
};

export default RejectSummary;
