import React, { useEffect } from "react";
import * as Sentry from "@sentry/react";
import css from "../../style/booking.module.css";
import { ImCross } from "react-icons/im";
import { useDispatch } from "react-redux";
import {
  postBookingFiles,
  deleteBookingFile,
} from "../../store/actions/bookingActions";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import { bookingFilesImageURL } from "../../store/constants/baseUrl";
import LightTooltip from "../Tooltip/tooltip";

const Upload = ({ bookingObject, handleUpdateBookingObject }) => {
  const [loader, setLoader] = React.useState(false);
  const dispatch = useDispatch();

  //function to upload media file
  const handleMediaFileUpload = (e) => {
    try {
      const formData = new FormData();
      const files = e.target.files;
      for (let i = 0; i < files.length; i++) {
        formData.append(files[0]?.type, files[i]);
      }
      postBookingFiles(
        bookingObject?.id,
        formData,
        setLoader,
        handleUpdateBookingObject
      );
      setLoader(true);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };
  const deleteFile = (fileId) => {
    try {
      setLoader(true);
      dispatch(deleteBookingFile(fileId, bookingObject, setLoader));
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };
  return (
    <div className={css?.uploadImageMainDiv}>
      <div className={css?.UploadDiv}>
        <div className={css?.uploadImageIcon}>
          <input
            style={{ width: "700px" }}
            id="upload-booking-image"
            type="file"
            name="Select File"
            multiple={true}
            className={css?.uploadImageCustom}
            onChange={(e) => handleMediaFileUpload(e)}
          />
        </div>
        <div className={css?.videoBottomText}>
          Upload video or pictures of the items getting moved or delivered.{" "}
          <LightTooltip
            title={
              <div className={css?.tooltipMainDiv}>
                <div className={css?.tooltipBullet}>
                  <div className={css?.tooltipDotBox}>
                    <div className={css?.tooltipDot}>.</div>
                  </div>
                  <div className={css?.tooltipText}>
                    By uploading pictures and video of the items that need to be
                    moved, our moovers can properly assess the job before they
                    arrive.
                  </div>
                </div>
                <div className={css?.tooltipBullet}>
                  <div className={css?.tooltipDotBox}>
                    <div className={css?.tooltipDot}>.</div>
                  </div>
                  <div className={css?.tooltipText}>
                    If the vehicle you have selected is too small or more
                    moovers are required to complete the job, they will contact
                    you once your job has been submitted and request the
                    appropriate adjustments to be made. No adjustments can be
                    made without your approval first.
                  </div>
                </div>
                <div className={css?.tooltipBullet}>
                  <div className={css?.tooltipDotBox}>
                    <div className={css?.tooltipDot}>.</div>
                  </div>
                  <div className={css?.tooltipText}>
                    Sending the proper vehicle and number of moovers will save
                    everyone time and money.
                  </div>
                </div>
              </div>
            }
          >
            <img
              src="images/tooltip.svg"
              style={{
                width: "20px",
                cursor: "pointer",
              }}
              alt=""
            />
          </LightTooltip>
        </div>
      </div>
      {bookingObject?.bookingFiles?.length ? (
        <div className={css?.bookingFilesMainDiv}>
          <div>Selected Files:</div>
          {bookingObject?.bookingFiles.map((file) => (
            <div className={css?.bookingFilesList}>
              <div className={css?.fileNameImgDiv}>
                <img
                  src={bookingFilesImageURL + file.fileUrl}
                  alt={file.fileUrl}
                  className={css?.bookingFileImg}
                />
                {file.fileUrl}
              </div>
              <ImCross
                className={css?.bookingFileDelBtn}
                onClick={() => deleteFile(file.id)}
              />
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}

      {loader ? (
        <div style={{ textAlignLast: "center" }}>
          <Box>
            <CircularProgress color="secondary" />
          </Box>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Upload;
