import React from "react";
import css from "../../style/deliveryInfo.module.css";
import { useHistory } from "react-router";

const ActionButtons = () => {
  const history = useHistory();
  const items = [
    { name: "Schedule a delivery", link: "Signup", image: "s1.svg" },
    { name: "Become a moover", link: "", image: "become_mover.svg" },
  ];

  const goToLink = (link) => {
    if (link === "Signup") history.push("/signup");
    else
      window.location.href =
        "https://moversignup.moovez.ca/Driver/MooverSignUp";
  };

  return (
    <div className={css?.s8Md}>
      {items.map((item) => (
        <div className={css?.s8Card} onClick={() => goToLink(item.link)}>
          <div className={css?.s8CardSubDiv}>
            <p className={css?.s8CardName}>{item.name}</p>
            <img
              src={`/images/${item.image}`}
              alt={item.name}
              className={css?.s8CardImg}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default ActionButtons;
