import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
// Info
import MobileFAQsPage from "./MobilePages/MobileFAQs";
import MobileContactUsPage from "./MobilePages/MobileContactUs";
import MobilePrivacyPage from "./MobilePages/MobilePrivacy";
import MobileTerms from "./MobilePages/MobileTerms";
import MobileIndex from "./MobilePages/MobileIndex";
import MobileVehicleInfo from "./MobilePages/MobileVehicleInfo";
import MobileBusinessDeliveryInfoPage from "./MobilePages/MobileBusinessDeliveryInfo";
import MobileMoverInfo from "./MobilePages/MobileMoverInfo";
import MobileDeliveryInfoPage from "./MobilePages/MobileDeliveryInfo";
// Booking
import MobileMooversPage from "./MobilePages/MobileMoovers";
import MobileMyTripsPage from "./MobilePages/MobileMyTrips";
import MobileSingleTripPage from "./MobilePages/MobileSingleTrip";
import MobileBookingPage from "./MobilePages/MobileBooking";
import MobileOrderSummary from "./MobilePages/MobileOrderSummary";
import MobileDeliveryNext from "./MobilePages/MobileDeliveryNext";
import MobileNextMooving from "./MobilePages/MobileNextMooving";
import MobileFeedBack from "./MobilePages/MobileFeedBack";
import MobileThankyouPage from "./MobilePages/MobileThankyou";
import MobileMoveCompleted from "./MobilePages/MobileMoveCompleted";
import MobileRejectSummary from "./MobilePages/MobileRejectSummary";
// Account
import MobileLoginPage from "./MobilePages/MobileLogin";
import MobileSignupPage from "./MobilePages/MobileSignup";
import MobileSignupBusinessPage from "./MobilePages/MobileSignupBusiness";
import MobileUserProfilePage from "./MobilePages/MobileUserProfile";
import MobileConfirmEmailPage from "./MobilePages/MobileConfirmEmail";
import MobileResetPasswordPage from "./MobilePages/MobileResetPassword";
import MobileForgotPasswordPage from "./MobilePages/MobileForgotPassword";
import MobileReferralSignupPage from "./MobilePages/MobileReferralSignup";
import MobileBusniessPage from "./MobilePages/MobileBusniess";
import MobileSettingPage from "./MobilePages/MobileSettings";
import MobileWalletPage from "./MobilePages/MobileWallet";
import MobileReferAFriend from "./MobilePages/MobileReferAFriend";

function App() {
  if (process.env.REACT_APP_MODE !== "development") console.log = () => {};
  return (
    <BrowserRouter>
      <Switch>
        {/* info */}
        <Route path="/FAQs" exact component={MobileFAQsPage} />
        <Route path="/ContactUs" exact component={MobileContactUsPage} />
        <Route path="/Privacy" exact component={MobilePrivacyPage} />
        <Route path="/terms" exact component={MobileTerms} />
        <Route path="/vehicle/:vehicleId" exact component={MobileVehicleInfo} />
        <Route path="/" exact component={MobileIndex} />

        {/* booking */}
        <Route path="/MoversNext" exact component={MobileMooversPage} />
        <Route path={"/BusinessNext"} exact component={MobileBusniessPage} />
        <Route path="/MyTrips/:userId" exact component={MobileMyTripsPage} />
        <Route path="/booking" exact component={MobileBookingPage} />
        <Route
          path="/bookingSummary/:id"
          exact
          component={MobileOrderSummary}
        />
        <Route path="/thankyou" exact component={MobileThankyouPage} />
        <Route path="/moveCompleted" exact component={MobileMoveCompleted} />
        <Route path="/rejectSummary" exact component={MobileRejectSummary} />
        <Route path="/DeliveryNext" exact component={MobileDeliveryNext} />
        <Route path="/MovingNext" exact component={MobileNextMooving} />
        <Route path="/feedback" exact component={MobileFeedBack} />
        <Route
          path="/SingleTrip/:userId/:bookingId"
          exact
          component={MobileSingleTripPage}
        />

        {/* <Route
          path="/forgotPassword"
          exact
          component={MobileForgotPasswordPage}
        /> */}
        {/* Account */}
        <Route path="/Login" exact component={MobileLoginPage} />
        <Route path="/Signup" exact component={MobileSignupPage} />
        <Route path="/business" exact component={MobileSignupBusinessPage} />
        <Route path="/deliveryInfo" component={MobileDeliveryInfoPage} />
        <Route
          path="/businessInfo"
          component={MobileBusinessDeliveryInfoPage}
        />
        <Route path="/moverInfo" component={MobileMoverInfo} />
        <Route
          path="/UserProfile/:email"
          exact
          component={MobileUserProfilePage}
        />
        <Route path="/settings/:email" exact component={MobileSettingPage} />
        <Route path="/wallet/:email" exact component={MobileWalletPage} />
        <Route
          path="/ConfirmEmail/:email/:phone"
          exact
          component={MobileConfirmEmailPage}
        />
        <Route
          path="/ResetPassword/:email"
          exact
          component={MobileResetPasswordPage}
        />
        <Route path="/referAFriend/:id" exact component={MobileReferAFriend} />

        <Route
          path="/ForgotPassword"
          exact
          component={MobileForgotPasswordPage}
        />
        <Route
          path="/RegisterMoovez/:code"
          exact
          component={MobileReferralSignupPage}
        />
        <Redirect from="*" to="/" />
      </Switch>
    </BrowserRouter>
  );
}
export default App;
