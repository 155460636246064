import React from "react";
import Layout from "../layouts/GeneralLayout";
import Privacy from "../screens/Info/Privacy";

const MobilePrivacyPage = () => {
  return (
    <Layout>
      <Privacy baseUrl={""} />
    </Layout>
  );
};

export default MobilePrivacyPage;
