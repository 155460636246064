import React from "react";
import UserProfile from "../screens/Account/UserProfile";
import Layout from "../layouts/GeneralLayout";
const MobileUserProfilePage = () => (
  <Layout>
    <UserProfile />
  </Layout>
);

export default MobileUserProfilePage;
