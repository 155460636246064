import React, { useState, useEffect } from "react";
// importing the css
import css from "../../style/booking.module.css";
import { BsFillPersonFill } from "react-icons/bs";
import { ImMobile2 } from "react-icons/im";
import { HiPlusCircle } from "react-icons/hi";
import * as Sentry from "@sentry/react";
import { handleValidatePhoneNumber } from "../../Utilities/general";

const RecipentInfo = ({
  bookingObject,
  handleUpdateBookingObject,
  errorObject,
  setErrorObject,
}) => {
  // alphabets
  const alphabets = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  const handleReturnText = (e) => {
    let text = e?.target?.value;
    if (!text) return "";
    let textArr = /[a-zA-Z][a-zA-Z ]*/.exec(text);
    return textArr?.length ? textArr.join("") : "";
  };

  return (
    <div className={css?.recipendInfoMainDiv}>
      <div className={css?.TopHeading} style={{ width: "100%" }}>
        Confirm details
      </div>
      <div className={css?.RecipentHeadingText}>
        Contact info for recipient who will be at each location
      </div>
      <div className={css?.recipentInfoCardsDiv}>
        <div className={css?.recipentInfoCardMainDiv}>
          <div className={css?.recipentInfoCardTopLine}>
            <div className={css?.recipentInfoCardBadge}>A</div>
          </div>
          <div className={css?.recipentInfoCardName}>
            <BsFillPersonFill className={css?.recipentInfoCardIcon} />
            <input
              className={css?.recipentInfoCardInput}
              placeholder="Name (Pickup location)"
              value={bookingObject?.pickUpPersonName}
              onChange={(e) => {
                setErrorObject({ ...errorObject, pickUpPersonName: false });
                handleUpdateBookingObject(
                  "pickUpPersonName",
                  handleReturnText(e),
                  "18.1"
                );
              }}
            />
          </div>
          <div className={css?.recipentInfoCardName}>
            <ImMobile2 className={css?.recipentInfoCardIcon} />
            <input
              value={bookingObject?.pickUpPersonPhone}
              className={css?.recipentInfoCardInput}
              placeholder="Mobile number"
              onChange={(e) => {
                setErrorObject({ ...errorObject, pickUpPersonPhone: false });
                handleValidatePhoneNumber(e, (num) => {
                  handleUpdateBookingObject("pickUpPersonPhone", num, "18.2");
                });
              }}
            />
          </div>
          <div className={css?.recipentInfoCardInstriction}>
            <HiPlusCircle className={css?.recipentInfoCardIcon} />
            <div
              contentEditable
              role="textbox"
              onInput={(e) =>
                handleUpdateBookingObject(
                  "pickUpInstructions",
                  e.target.value,
                  "18.3"
                )
              }
              className={css?.recipentInfoCardInput}
            >
              {bookingObject?.pickUpInstructions
                ? bookingObject?.pickUpInstructions
                : "Additional instructions (optional)"}
            </div>
          </div>
        </div>
        {bookingObject?.stops?.map((stopData, index) => (
          <div className={css?.recipentInfoCardMainDiv}>
            <div className={css?.recipentInfoCardTopLine}>
              <div className={css?.recipentInfoCardBadge}>
                {alphabets[index + 1]}
              </div>
            </div>
            <div className={css?.recipentInfoCardName}>
              <BsFillPersonFill className={css?.recipentInfoCardIcon} />
              <input
                className={css?.recipentInfoCardInput}
                placeholder={`Name (Pickup ${index + 2} location)`}
                value={bookingObject?.stops[index]?.personName}
                onChange={(e) => {
                  try {
                    const stopInfo = bookingObject?.stops;
                    stopInfo[index].personName = handleReturnText(e);
                    handleUpdateBookingObject("stops", stopInfo, "18.4");
                  } catch (error) {
                    Sentry.captureException(error);
                    console.log(error);
                  }
                }}
              />
            </div>
            <div className={css?.recipentInfoCardName}>
              <ImMobile2 className={css?.recipentInfoCardIcon} />
              <input
                className={css?.recipentInfoCardInput}
                placeholder="Recipient mobile number"
                value={bookingObject?.stops[index]?.personPhone}
                onChange={(e) =>
                  handleValidatePhoneNumber(e, (num) => {
                    const stopInfo = bookingObject?.stops;
                    stopInfo[index].personPhone = num;
                    handleUpdateBookingObject("stops", stopInfo, "18.5");
                  })
                }
              />
            </div>
            <div className={css?.recipentInfoCardInstriction}>
              <HiPlusCircle className={css?.recipentInfoCardIcon} />
              <div
                contentEditable
                role="textbox"
                onInput={(e) => {
                  try {
                    const stops = bookingObject?.stops;
                    stops[index].instructions = e.target.value;
                    handleUpdateBookingObject("stops", stops, "18.6");
                  } catch (error) {
                    Sentry.captureException(error);
                    console.log(error);
                  }
                }}
                className={css?.recipentInfoCardInput}
              >
                {bookingObject?.stops[`stop${index}`]?.instructions
                  ? bookingObject?.stops[`stop${index}`]?.instructions
                  : "Additional instructions (optional)"}
              </div>
              {/* <input
                style={{ backgroundColor: "white" }}
                className={css?.recipentInfoCardInput}
                placeholder="Instruction (optional)"
                value={bookingObject?.stops[index]?.instructions}
                onChange={(e) => {
                  const stops = bookingObject?.stops;
                  stops[index].instructions = e.target.value;
                  setBookingObject({
                    ...bookingObject,
                    stops: stops,
                  });
                }}
              /> */}
            </div>
          </div>
        ))}
        {bookingObject?.dropoffLocation && (
          <div className={css?.recipentInfoCardMainDiv}>
            <div className={css?.recipentInfoCardTopLine}>
              <div className={css?.recipentInfoCardBadge}>
                {bookingObject?.stops
                  ? alphabets[1 + bookingObject?.stops?.length]
                  : "B"}
              </div>
            </div>
            <div className={css?.recipentInfoCardName}>
              <BsFillPersonFill className={css?.recipentInfoCardIcon} />
              <input
                className={css?.recipentInfoCardInput}
                placeholder="Name (Drop-off location)"
                value={bookingObject?.dropOffPersonName}
                onChange={(e) => {
                  setErrorObject({ ...errorObject, dropOffPersonName: false });
                  handleUpdateBookingObject(
                    "dropOffPersonName",
                    handleReturnText(e),
                    "18.7"
                  );
                }}
              />
            </div>
            <div className={css?.recipentInfoCardName}>
              <ImMobile2 className={css?.recipentInfoCardIcon} />
              <input
                value={bookingObject?.dropOffPersonPhone}
                className={css?.recipentInfoCardInput}
                placeholder="Mobile number"
                onChange={(e) => {
                  setErrorObject({ ...errorObject, dropOffPersonPhone: false });
                  handleValidatePhoneNumber(e, (num) => {
                    handleUpdateBookingObject(
                      "dropOffPersonPhone",
                      num,
                      "18.8"
                    );
                  });
                }}
              />
            </div>
            <div className={css?.recipentInfoCardInstriction}>
              <HiPlusCircle className={css?.recipentInfoCardIcon} />
              <div
                contentEditable
                role="textbox"
                onInput={(e) =>
                  handleUpdateBookingObject(
                    "dropOffInstructions",
                    e.target.value,
                    "18.9"
                  )
                }
                className={css?.recipentInfoCardInput}
              >
                {bookingObject?.dropOffInstructions
                  ? bookingObject.dropOffInstructions
                  : "Additional instructions (optional)"}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RecipentInfo;
