import React, { useState } from "react";
import ModalComponent from "../../components/Modals/Modal";
import { FaLock, FaTimesCircle, FaUnlockAlt } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { changePassword } from "../../store/actions/forgotPasswordActions";
import css from "../../style/loginStyles.module.css";
import * as Sentry from "@sentry/react";

function ResetPassword() {
  // status is password changed or not
  const [status, setStatus] = useState(false);
  // state to display errors
  const [error, seterror] = useState("");
  // code from Params
  const { email } = useParams();
  //history for routing
  const history = useHistory();
  // dispatch actions
  const dispatch = useDispatch();
  //oldPassword
  const [oldPassword, setoldPassword] = useState("");
  const [oldPasswordActive, setoldPasswordActive] = useState(false);
  //password
  const [password, setpassword] = useState("");
  const [passwordStrength, setpasswordStrength] = useState(0);
  //confirmPass
  const [confirmPassword, setconfirmPassword] = useState("");
  // check password strength
  const handleSetPassword = (e) => {
    try {
      let strengthLevel = 0;
      const passArray = [...e];
      setpassword(e);
      if (e !== 0) {
        const hasLowerCase = passArray?.filter(
          (letter) => letter >= "a" && letter <= "z"
        );
        const hasUpperCase = passArray?.filter(
          (letter) => letter >= "A" && letter <= "Z"
        );
        const hasNumber = passArray?.filter(
          (letter) => letter >= "0" && letter <= "9"
        );
        const hasSC1 = passArray?.filter(
          (letter) => letter >= "!" && letter <= "/"
        );
        const hasSC2 = passArray?.filter(
          (letter) => letter >= ":" && letter <= "@"
        );
        const hasSC3 = passArray?.filter(
          (letter) => letter >= "[" && letter <= "`"
        );
        const hasSC4 = passArray?.filter(
          (letter) => letter >= "{" && letter <= "~"
        );
        if (hasLowerCase?.length > 0) {
          strengthLevel = strengthLevel + 20;
        }
        if (hasUpperCase?.length > 0) {
          strengthLevel = strengthLevel + 20;
        }
        if (hasNumber?.length > 0) {
          strengthLevel = strengthLevel + 20;
        }
        if (
          hasSC1?.length > 0 ||
          hasSC2?.length > 0 ||
          hasSC3?.length > 0 ||
          hasSC4?.length > 0
        ) {
          strengthLevel = strengthLevel + 20;
        }
        if (e?.length > 5) {
          strengthLevel = strengthLevel + 20;
        }
      }
      setpasswordStrength(strengthLevel);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const handleResetPassword = () => {
    try {
      if (oldPassword && password && confirmPassword) {
        if (passwordStrength === 100) {
          if (password === confirmPassword) {
            const resetPasswordData = {
              email: email,
              currentPassword: oldPassword,
              newPassword: password,
            };
            console.log(
              "new pass data from reset pass component",
              resetPasswordData
            );
            dispatch(changePassword(resetPasswordData, setStatus, seterror));
          } else {
            alert("Passwords do not match");
          }
        } else {
          alert(
            "Password must be of at least 6 characters, with both upper and lower chase letters,numbers and special characters."
          );
        }
      } else {
        alert("Please fill all the fields");
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const handleModalClose = () => {
    try {
      setStatus(false);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 270,
    minheight: "100px",
    height: 170,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div className={css?.loginMainDiv}>
      <ModalComponent
        text={"Password Change Successfully."}
        openState={status}
        closeFunction={handleModalClose}
        btnText1={"Okay"}
        btnClickFunc1={async () => {
          handleModalClose();
          history.push("/");
        }}
      />
      <div>
        <img src="/images/Moovez-Dark.svg" alt="" className={css?.loginImage} />
        <div class={css?.posterMainHeading}>Change Password</div>
        <div class={css?.inputBox}>
          <FaLock />
          <input
            placeholder="Old password"
            value={oldPassword}
            type="password"
            className={css?.fullInput}
            onFocus={() => setoldPasswordActive(true)}
            onChange={(e) => setoldPassword(e?.target?.value)}
          />
          {oldPasswordActive && !oldPassword && (
            <span className={css?.crossIconFull}>
              <FaTimesCircle />
            </span>
          )}
        </div>
        {/* password input  */}
        <div class={css?.inputBox}>
          <FaUnlockAlt />
          <input
            placeholder="Password"
            value={password}
            type="password"
            className={css?.fullInput}
            onChange={(e) => handleSetPassword(e?.target?.value)}
          />
        </div>
        <div class={css?.inputBox}>
          <FaUnlockAlt />
          <input
            placeholder="Confirm password"
            value={confirmPassword}
            type="password"
            className={css?.fullInput}
            onChange={(e) => setconfirmPassword(e?.target?.value)}
          />
        </div>
        <div style={{ color: "red" }}>{error ? error : ""}</div>
        <div className={css?.signupBtnMainDiv}>
          <button onClick={handleResetPassword} className={css?.signupBtn}>
            Change Password
          </button>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
