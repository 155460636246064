import React, { useState } from "react";
import Layout from "../layouts/GeneralLayout";
import BookingType from "../screens/Info/BookingType";
import Modal from "../components/modal";
import { useHistory } from "react-router-dom";
const cover = require("../assets/images/business_banner.svg").default;
const MobileBusniessPage = () => {
  const [showModal, setShow] = useState(false);
  const history = useHistory();
  const handleShowModal = () => setShow(!showModal);
  const openInfoPage = () => {
    history.push("businessInfo");
  };
  return (
    <>
      <Layout
        infoIcon={true}
        pageName="What is a business job?"
        headerClickFunction={handleShowModal}
      >
        <BookingType
          baseUrl={"/mobile"}
          cover={cover}
          title="Deliver faster"
          p1="The new last-mile delivery
          solution for your business."
          p2="We help to reduce your
          operational costs by taking care
          of your logistics. No more need
          to manage your own internal
          delivery eet. Outsource it to us
          and simply pay for deliveries
          when you need them."
          bottomButtonText={"Get started"}
          bookingType="Business"
        />
      </Layout>
      <Modal
        visible={showModal}
        turnItOff={() => {
          setShow(false);
        }}
        title={"Business deliveries"}
        p1={"To get started right away, make sure you follow these guidelines:"}
        p2={"Common use cases:"}
        list={[
          "Fill out the online form so we can learn more about your What is business? business and your delivery needs",
          "Once completed, one of our Business Development Representatives will reach out to you and explain the next steps.",
        ]}
        list2={[
          "Last-mile delivery for your business.",
          "Fast and reliable on-demand, same-day or advance-order deliveries for businesses of any size",
          "Our business clients include: real estate agents, home stagers/interior designers, furniture stores, retail stores, construction suppliers, construction companies, etc.",
        ]}
        greenBtnText="Got it"
        openInfoPage={openInfoPage}
      />
    </>
  );
};

export default MobileBusniessPage;
