import React, { useState } from "react";
import css from "../../style/loginStyles.module.css";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ConfirmEmailAction } from "../../store/actions/loginActions";
import { FaEnvelope } from "react-icons/fa";
import * as Sentry from "@sentry/react";
const ConfirmEmail = () => {
  // verification code
  const [verificationcode, setverificationcode] = useState();
  const [error, setError] = useState();
  //history for routing
  const history = useHistory();
  // dispatch actions
  const dispatch = useDispatch();
  // email and user id from params
  const { email, phone } = useParams();
  // verification cide
  const [verificationcodeActive, setverificationcodeActive] = useState(false);
  const [count, setCount] = useState(0);
  // forgot password
  const handleConfirmEMail = () => {
    try {
      if (verificationcode) {
        dispatch(
          ConfirmEmailAction(email, verificationcode, history, setError)
        );
      } else {
        alert("Please fill all the fields");
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };
  return (
    <div className={css?.loginMainDiv}>
      <div>
        <img src="/images/Moovez-Dark.svg" alt="" className={css?.loginImage} />
        <div className={css?.posterMainHeading}>Confirm Email?</div>
        <div className={css?.posterText2}>
          A verification code has been sent to your email {email} and cellphone{" "}
          {phone}. Please enter that code below:
        </div>
        <div class={css?.inputBox}>
          <FaEnvelope style={{ color: "gray" }} />
          <input
            placeholder="Code"
            className={css?.fullInput}
            value={verificationcode}
            onFocus={() => setverificationcodeActive(true)}
            onChange={(e) => setverificationcode(e?.target?.value)}
          />
        </div>
      </div>

      <div style={{ color: "red" }}>{error ? error : ""}</div>
      <div className={css?.signupBtnMainDiv}>
        <button onClick={handleConfirmEMail} className={css?.signupBtn}>
          Confirm Email
        </button>
        <div className={css?.signinTxtForgotPassword}>
          Back to{" "}
          <span
            className={css?.signinTxt}
            onClick={() => history.push("/Signup")}
          >
            Sign up
          </span>
        </div>
      </div>
    </div>
  );
};

export default ConfirmEmail;
