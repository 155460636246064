import React from "react";
import Booking from "../screens/Booking/Booking";
import Layout from "../layouts/GeneralLayout";

const MobileBookingPage = () => {
  return (
    <Layout header={false}>
      <Booking />
    </Layout>
  );
};

export default MobileBookingPage;
