import React, { useEffect, useState } from "react";
import { AiOutlineMail } from "react-icons/ai";
import { FaLock } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ModalComponent from "../../components/Modals/Modal";
import { login } from "../../store/actions/loginActions";
import css from "../../style/loginStyles.module.css";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import * as Sentry from "@sentry/react";

function WemoovezLogin() {
  // state to display errors
  const [error, seterror] = useState("");
  //history for routing
  const history = useHistory();
  // dispatch actions
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  // show password toggle
  const [showPassword, setShowPassword] = useState("password");
  // email
  const [email, setemail] = useState("");
  // password
  const [password, setpassword] = useState("");
  // terms and conditions
  const [TnC, setTnC] = useState(false);
  const [modalFields, setModalFields] = useState({
    open: false,
    text: "",
    btnText1: "Ok",
    closeFunc: () => {
      setModalFields({ ...modalFields, open: false });
    },
  });

  // useEffect to show error modal
  useEffect(() => {
    try {
      if (error) {
        setModalFields({
          ...modalFields,
          open: true,
          text: error,
        });
        seterror("");
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [error]);

  // Sign In
  const handleLogin = () => {
    try {
      if (email && password) {
        if (ValidateEmail(email)) {
          const loginData = {
            email: email,
            password: password,
          };
          console.log("login data from signup component", loginData);
          setLoader(true);
          dispatch(login(loginData, seterror, history, setLoader));
        } else {
          setModalFields({
            ...modalFields,
            open: true,
            text: "Please enter a valid email address!",
          });
        }
      } else {
        setModalFields({
          ...modalFields,
          open: true,
          text: "Please fill all the fields!",
        });
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const toggleShowPassword = () => {
    if (showPassword === "text") setShowPassword("password");
    else setShowPassword("text");
  };

  //Validating Email
  function ValidateEmail(mail) {
    try {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return true;
      }
      return false;
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }
  return (
    <div className={css?.loginMainDiv}>
      <ModalComponent
        text={modalFields.text}
        openState={modalFields.open}
        closeFunction={() => {
          setModalFields({ ...modalFields, open: false });
        }}
        btnText1={modalFields.btnText1}
        btnClickFunc1={modalFields.closeFunc}
      />
      <img src="/images/Moovez-Dark.svg" alt="" className={css?.loginImage} />
      <div className={css?.posterMainHeading}>Welcome back to moovez!</div>
      <div className={css?.posterText}>Let’s start moving.</div>
      <div className={css?.inputBox}>
        <AiOutlineMail />
        <input
          value={email}
          placeholder="Email"
          onChange={(e) => setemail(e.target.value)}
          className={css?.fullInput}
        ></input>
      </div>
      <div className={css?.inputBox}>
        <FaLock />
        <input
          value={password}
          type={showPassword}
          placeholder="Password"
          className={css?.passwordInput}
          onChange={(e) => setpassword(e?.target?.value)}
        ></input>
        {showPassword === "password" ? (
          <BsFillEyeFill
            className={css?.passwordEye}
            onClick={() => toggleShowPassword()}
          />
        ) : (
          <BsFillEyeSlashFill
            className={css?.passwordEye}
            onClick={() => toggleShowPassword()}
          />
        )}
      </div>
      {/* handleLogin */}
      <div className={css?.TnCMainDiv}>
        <input
          type="checkbox"
          checked={TnC}
          onChange={(e) => setTnC(e.target.checked)}
          className={css?.TnCCheckbox}
        ></input>
        Keep me signed in
      </div>
      <div className={css?.signupBtnMainDiv}>
        <button className={css?.signupBtn} onClick={handleLogin}>
          Log in
        </button>
        <div
          className={css?.signinTxtForgotPassword}
          onClick={() => history.push("/forgotPassword")}
        >
          Forgot password?{" "}
        </div>
      </div>
      <div className={css?.signinRedirect}>
        Don't have an account?{" "}
        <span
          className={css?.signinTxt}
          onClick={() => history.push("/Signup")}
        >
          Sign up
        </span>
      </div>
      {/* <AuthFooter showIcon={true} /> */}
    </div>
  );
}

export default WemoovezLogin;
