import React, { useState } from "react";
import css from "../../style/moveCompleted.module.css";
import { useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";

const MoveCompleted = () => {
  const [TNC, setTNC] = useState(false);
  const history = useHistory();
  const handleCompleteMove = () => {
    try {
      if (TNC) {
      } else alert("Please accept the terms and conditions and privacy policy");
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };
  return (
    <div className={css?.mainDiv}>
      <div className={css?.cardMainDiv}>
        <div className={css?.headingMainDiv}>Another job well done!</div>
        <div className={css?.BtnStyle} onCLick={() => handleCompleteMove()}>
          Move completed
        </div>
        <div className={css?.bottomText}>
          <input
            type="checkbox"
            className={css?.checkBox}
            onChange={(e) => setTNC(e.target.value)}
          />
          By clicking you accept our{" "}
          <span
            className={css?.highlightedText}
            onClick={() => history.push("/terms")}
          >
            terms & condition
          </span>{" "}
          and{" "}
          <span
            className={css?.highlightedText}
            onClick={() => history.push("/Privacy")}
          >
            privacy policy
          </span>
        </div>
      </div>
    </div>
  );
};

export default MoveCompleted;
