import React from "react";
import css from "../../style/Layout.module.css";
import { useHistory } from "react-router-dom";
import { FaClock, FaHeadphonesAlt, FaHome } from "react-icons/fa";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";

const GeneralFooter = () => {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const [open2, setOpen2] = React.useState(false);

  const handleTooltipClose2 = () => {
    console.log("AB CLOSE HOGAYA");
    setOpen2(false);
  };

  const handleTooltipOpen2 = () => {
    console.log("OPEN HOGAYA");
    setOpen2(true);
  };

  return (
    <div className={css?.footerMainDiv2}>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          title="Coming soon!"
          disableFocusListener
          disableHoverListener
          disableTouchListener
          onClose={handleTooltipClose}
          open={open}
        >
          <div onClick={handleTooltipOpen}>
            <FaClock
              style={{ fontSize: "3.5vh", margin: "0 10px", color: "grey" }}
            />
          </div>
        </Tooltip>
      </ClickAwayListener>
      <div className={css?.homeBtn} onClick={() => history.push("/")}>
        <FaHome style={{ fontSize: "3.5vh" }} />
      </div>
      <ClickAwayListener onClickAway={handleTooltipClose2}>
        <Tooltip
          title="Coming soon!"
          disableFocusListener
          disableHoverListener
          disableTouchListener
          onClose={handleTooltipClose2}
          open={open2}
        >
          <div onClick={handleTooltipOpen2}>
            <FaHeadphonesAlt
              style={{ fontSize: "3.5vh", margin: "0 10px", color: "grey" }}
            />
          </div>
        </Tooltip>
      </ClickAwayListener>
    </div>
  );
};

export default GeneralFooter;
