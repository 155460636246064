import React, { useState, useEffect } from "react";
// importing the css
import css from "../../style/booking.module.css";
// importing components
import { Modal, Box } from "@material-ui/core";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm2 from "../../components/stripe/PaymentForm2";
import { bookingIfCardSaved } from "../../store/actions/paymentActions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateBooking } from "../../store/actions/bookingActions";
import { stripeTestPromise } from "../../store/constants/baseUrl";
import RoundButton from "../../components/Booking/RoundButton";
import { FaCheckSquare, FaSquare, FaEdit } from "react-icons/fa";
import * as Sentry from "@sentry/react";
//Modal Imports
import Typography from "@mui/material/Typography";

const PaymentScreen = ({ bookingObject, handleUpdateBookingObject }) => {
  // dispatch redux actions
  const dispatch = useDispatch();
  // history for routing
  const history = useHistory();
  // state to restrict multipel clicks
  const [buttonClicked, setButtonClicked] = useState(false);
  // State to open CC modal
  const [openCreditCardModal, setopenCreditCardModal] = useState(false);
  //toggle for card window name
  const [firstNameToggle, setfirstNameToggle] = useState(true);
  //payment credentials
  const [fullName, setfullName] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [TermsAndConditions, setTermsAndConditions] = useState();

  //Modal Consts
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  // function to close CC modal
  const handleCloseCardModal = () => {
    try {
      setopenCreditCardModal(false);
      setfirstNameToggle(true);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };
  // function to place order and make payment
  const handlePlaceOrder = () => {
    try {
      if (bookingObject?.user?.hasCard) {
        dispatch(
          bookingIfCardSaved(bookingObject?.id, bookingObject?.type, history)
        );
      } else {
        setopenCreditCardModal(true);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  // return to confirmation screen
  const handleGoToConfirmOrderScreen = () => {
    try {
      updateBooking(
        bookingObject?.id,
        {
          ...bookingObject,
          tripStatus: "Confirmation",
        },
        handleUpdateBookingObject
      );
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  //Modal styles
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    const user = localStorage.getItem("wemoovezUserProfile");
    if (user) setUserInfo(JSON.parse(user));
  }, []);

  useEffect(() => {
    try {
      let makeBtnClickable;

      if (buttonClicked) {
        makeBtnClickable = setTimeout(() => {
          setButtonClicked(false);
        }, 10000);
      }
      return () => clearTimeout(makeBtnClickable);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [buttonClicked]);
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ textAlign: "center", color: "orange" }}
          >
            Oops !
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            style={{ textAlign: "center" }}
          >
            Please accept terms and conditions of Moovez
          </Typography>

          <div style={{ marginLeft: "12%" }}>
            <RoundButton text="Ok" clickFunction={() => setOpen(false)} />
          </div>
        </Box>
      </Modal>
      <div className={css?.paper}>
        <div className={css?.costBreakdownTopText}>Price Breakdown</div>
        <div className={css?.costBreakdownHeading}>Breakdown of fare</div>
        <div className={css?.priceDiv}>
          {["Delivery", "Business"].includes(bookingObject?.type) ? (
            <div className={css?.priceDivTop}>
              <div className={css?.priceBreakdownLine}>
                <span className={css?.priceBreakdownLeftWord}>Base Fare</span>
                <span className={css?.priceBreakdownRightWord}>
                  ${bookingObject?.bookingTotalModel?.baseFare ?? "0"}
                </span>
              </div>
              <div className={css?.priceBreakdownLine}>
                <span className={css?.priceBreakdownLeftWord}>Distance</span>
                <span className={css?.priceBreakdownRightWord}>
                  ${bookingObject?.bookingTotalModel?.totalDistance ?? "0"}
                </span>
              </div>
              <div className={css?.priceBreakdownLine}>
                <span className={css?.priceBreakdownLeftWord}>Labour</span>
                <span className={css?.priceBreakdownRightWord}>
                  ${bookingObject?.bookingTotalModel?.labourSurcharge ?? "0"}
                </span>
              </div>
              <div className={css?.priceBreakdownLine}>
                <span className={css?.priceBreakdownLeftWord}>
                  Extra moover
                </span>
                <span className={css?.priceBreakdownRightWord}>
                  $
                  {bookingObject?.bookingTotalModel
                    ?.delivery_ExtraMooverCharge ?? "0"}
                </span>
              </div>
              <div
                className={css?.priceBreakdownLine}
                style={{ fontStyle: "italic" }}
              >
                <span className={css?.priceBreakdownLeftWord}>Subtotal</span>
                <span className={css?.priceBreakdownRightWord}>
                  ${bookingObject?.bookingTotalModel?.TotalChargeBTax ?? "0"}
                </span>
              </div>
            </div>
          ) : (
            <div className={css?.priceDivTop}>
              <div className={css?.priceBreakdownLine}>
                <span className={css?.priceBreakdownLeftWord}>Hourly rate</span>
                <span className={css?.priceBreakdownRightWord}>
                  ${bookingObject?.bookingTotalModel?.hourlyRate ?? "0"}
                </span>
              </div>
              <div className={css?.priceBreakdownLine}>
                <span className={css?.priceBreakdownLeftWord}>
                  Work time (hrs)
                </span>
                <span className={css?.priceBreakdownRightWord}>
                  {bookingObject?.bookingTotalModel?.workTime}
                </span>
              </div>
              <div className={css?.priceBreakdownLine}>
                <span className={css?.priceBreakdownLeftWord}>
                  Travel time (hrs)
                </span>
                <span className={css?.priceBreakdownRightWord}>
                  {bookingObject?.bookingTotalModel?.travelTime}
                </span>
              </div>
              <div className={css?.priceBreakdownLine}>
                <span className={css?.priceBreakdownLeftWord}>
                  Est. subtotal
                </span>
                <span className={css?.priceBreakdownRightWord}>
                  {bookingObject?.bookingTotalModel?.totalChargeBTax}
                </span>
              </div>
            </div>
          )}
          <div className={css?.priceDivMid}>
            <div className={css?.priceBreakdownLine}>
              <span className={css?.priceBreakdownLeftWord}>
                Total Service Fee
              </span>
              <span className={css?.priceBreakdownRightWord}>
                ${bookingObject?.bookingTotalModel?.totalServiceFee ?? "0"}
              </span>
            </div>
            <div className={css?.priceBreakdownLine}>
              <span className={css?.priceBreakdownLeftWord}>
                GST ( {bookingObject?.bookingTotalModel?.totalTaxGstpstrate}% )
              </span>
              <span className={css?.priceBreakdownRightWord}>
                ${bookingObject?.bookingTotalModel?.gstandPst ?? "0"}
              </span>
            </div>
            <div className={css?.priceBreakdownLine}>
              <span className={css?.priceBreakdownLeftWord}>Discount</span>
              <span className={css?.priceBreakdownRightWord}>
                ${bookingObject?.bookingTotalModel?.discount ?? "0"}
              </span>
            </div>
          </div>
          <div className={css?.priceDivBottom}>
            <div className={css?.priceBreakdownLine}>
              <span className={css?.priceBreakdownLeftWord}>Total Charge</span>
              <span className={css?.priceBreakdownRightWord}>
                ${bookingObject?.bookingTotalModel?.totalCharge ?? "0"}
              </span>
            </div>
          </div>
        </div>
        <Modal
          open={openCreditCardModal}
          onClose={handleCloseCardModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={css?.cardWindowMainDiv}>
            <div className={css?.cardPaymentProcess}>Payment Process</div>
            <div className={css?.checkoutInfo}>Checkout Information</div>
            <div className={css?.cardWindowInfoText}>
              Please add a payment method so we can pair your delivery to a
              driver. You won't be charged until a successful delivery has been
              made. Select{" "}
              <span style={{ fontWeight: "bold" }}>[Save &amp; Pay Now]</span>{" "}
              to save payment method to your account for the next delivery. This
              is a secure AES-256 encrypted payment.
            </div>
            <div className={css?.accountDetailsBoxInputBox}>
              <input
                placeholder="Full name"
                value={
                  fullName
                    ? fullName
                    : `${userInfo?.firstName ? userInfo.firstName : ""} ${
                        userInfo?.lastName ? userInfo.lastName : ""
                      }`
                }
                onChange={(e) => setfullName(e.target.value)}
                className={css?.accountDetailsBoxInputBoxInput}
              ></input>
              <span
                className={css?.accountDetailsBoxInputBoxEditIcon}
                onClick={() => setfirstNameToggle(!firstNameToggle)}
              >
                <FaEdit />
              </span>
            </div>
            <div className={css?.accountDetailsBoxInputBox}>
              <Elements stripe={stripeTestPromise}>
                <PaymentForm2
                  bookingObject={bookingObject}
                  submitWrapper={(cb, e) => {
                    e.preventDefault();
                    if (!buttonClicked) {
                      setButtonClicked(true);
                      cb(e);
                    }
                  }}
                />
              </Elements>
            </div>
            <div className={css?.confirmationLine}>
              {TermsAndConditions ? (
                <FaCheckSquare
                  style={{ margin: "0 8px 0 3px", color: "var(--primary)" }}
                  onClick={() => setTermsAndConditions(!TermsAndConditions)}
                />
              ) : (
                <FaSquare
                  style={{
                    margin: "0 8px 0 3px",
                    color: "var(--primary)",
                  }}
                  onClick={() => setTermsAndConditions(!TermsAndConditions)}
                />
              )}
              I have read and accepted moovez's terms of service
            </div>
            <div className={css?.payNowButtons}>
              <button
                onClick={handleCloseCardModal}
                className={css?.cancelButton}
              >
                Cancel
              </button>
              <button form="payment-in-booking" className={css?.payNowButton}>
                Pay Now
              </button>
            </div>
          </Box>
        </Modal>
        <span className={css?.priceBreakdownCloseButton}>
          <button
            onClick={handlePlaceOrder}
            className={css?.confirmPickupButton}
          >
            Place Order
          </button>
          <button
            onClick={handleGoToConfirmOrderScreen}
            className={css?.editTripbtn}
          >
            Return
          </button>
        </span>
      </div>
    </div>
  );
};

export default PaymentScreen;
