import React, { useState, useEffect } from "react";
import css from "../../style/signupStyles.module.css";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import ModalComponent from "../../components/Modals/Modal";
import BusinessDropdown from "../../components/Dropdowns/BusinessDropdown";
import {
  // getBusinessAverageDeliveries,
  getBusinessCurrentIndustry,
  //   // getBusinessGeneralLeadTime,
  //   // getBusinessOrderPreference,
  //   // getBusinessPreferedContactMethod,
  //   // getBusinessVehicleOftenRequired,
  postBookingSignup,
} from "../../store/actions/businessActions";
import * as Sentry from "@sentry/react";
import { handleValidatePhoneNumber } from "../../Utilities/general";

function BusinessSignup() {
  // state to display errors
  const [error, seterror] = useState("");
  //history for routing
  const history = useHistory();
  // dispatch actions
  const dispatch = useDispatch();
  // custom alert state
  const [modalFields, setModalFields] = useState({
    open: false,
    text: "",
    btnText1: "Ok",
    closeFunc: () => {
      setModalFields({ ...modalFields, open: false });
    },
  });
  // Business dropdown data
  // const [BusinessAverageDeliveries, setBusinessAverageDeliveries] = useState(
  //   []
  // );
  const [BusinessCurrentIndustry, setBusinessCurrentIndustry] = useState([]);
  // const [BusinessGeneralLeadTime, setBusinessGeneralLeadTime] = useState([]);
  // const [BusinessOrderPreference, setBusinessOrderPreference] = useState([]);
  // const [BusinessPreferedContactMethod, setBusinessPreferedContactMethod] =
  //   useState([]);
  // const [BusinessVehicleOftenRequired, setBusinessVehicleOftenRequired] =
  //   useState([]);

  const [businessObject, setBusinessObject] = useState({
    name: "",
    // lastName: "",
    companyName: "",
    // jobTitle: "",
    contactNumber: "",
    email: "",
    // contactId: "",
    industryId: "",
    // vehicleId: "",
    // averageDeliveries: "",
    // lalaMoveId: "",
    // bulkOrderDelivery: "",
    // leadTimeId: "",
  });

  const HandleChange = (e) => {
    try {
      setBusinessObject({ ...businessObject, [e.target.name]: e.target.value });
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  // useEffect to show error modal
  useEffect(() => {
    try {
      if (error) {
        setModalFields({
          ...modalFields,
          open: true,
          text: error,
        });
        seterror("");
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [error]);

  // useEffect to show error modal
  useEffect(() => {
    try {
      // dispatch(getBusinessAverageDeliveries(setBusinessAverageDeliveries));
      dispatch(getBusinessCurrentIndustry(setBusinessCurrentIndustry));
      // dispatch(getBusinessGeneralLeadTime(setBusinessGeneralLeadTime));
      // dispatch(getBusinessOrderPreference(setBusinessOrderPreference));
      // dispatch(
      //   getBusinessPreferedContactMethod(setBusinessPreferedContactMethod)
      // );
      // dispatch(
      //   getBusinessVehicleOftenRequired(setBusinessVehicleOftenRequired)
      // );
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, []);

  function ValidateEmail(mail) {
    try {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return true;
      }
      setModalFields({
        ...modalFields,
        open: true,
        text: "You have entered an invalid email address!",
      });
      return false;
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }

  const handleCheckNullOrUndefined = (item) => {
    try {
      if (item === null || item === undefined) return false;
      return true;
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const handleBusinessSignup = () => {
    try {
      if (ValidateEmail(businessObject.email))
        if (
          businessObject.name &&
          // businessObject.lastName &&
          businessObject.companyName &&
          // businessObject.jobTitle &&
          businessObject.contactNumber &&
          businessObject.email
        ) {
          if (
            // handleCheckNullOrUndefined(businessObject.averageId) &&
            // handleCheckNullOrUndefined(businessObject.contactId) &&
            handleCheckNullOrUndefined(businessObject.industryId)
            // &&
            // handleCheckNullOrUndefined(businessObject.vehicleId) &&
            // handleCheckNullOrUndefined(businessObject.lalaMoveId) &&
            // handleCheckNullOrUndefined(businessObject.leadTimeId)
          ) {
            dispatch(
              postBookingSignup(
                {
                  ...businessObject,
                  contactNumber: parseInt(businessObject.contactNumber),
                  bulkOrderDelivery:
                    businessObject.bulkOrderDelivery === "true" ? true : false,
                },
                history
              )
            );
          } else alert("Please select all the options");
        } else alert("Please fill all fields");
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const inputFields = [
    {
      title: "Tell us about your business.",
      inputFields: [
        {
          name: "name",
          placeholder: "Contact Name",
          value: businessObject.name,
          onChange: HandleChange,
        },
        // {
        //   name: "lastName",
        //   placeholder: "Last Name",
        //   value: businessObject.lastName,
        //   onChange: HandleChange,
        // },
        {
          name: "companyName",
          placeholder: "Business Name",
          value: businessObject.companyName,
          onChange: HandleChange,
        },
        // {
        //   name: "jobTitle",
        //   placeholder: "Job Title",
        //   value: businessObject.jobTitle,
        //   onChange: HandleChange,
        // },
        //   ],
        // },
        // {
        //   title: "Contact info",
        //   inputFields: [
        {
          name: "contactNumber",
          placeholder: "Phone",
          value: businessObject.contactNumber,
          onChange: (e) =>
            handleValidatePhoneNumber(e, (num) =>
              setBusinessObject({
                ...businessObject,
                contactNumber: num,
              })
            ),
        },
        {
          name: "email",
          placeholder: "Email",
          value: businessObject.email,
          onChange: HandleChange,
        },
      ],
    },
  ];

  const dropdownList = [
    // {
    //   title: "Preferred contact method",
    //   options: BusinessPreferedContactMethod,
    //   changeFunction: (e) =>
    //     setBusinessObject({ ...businessObject, contactId: e.target.value }),
    //   value: businessObject.contactId,
    //   idKey: "contactId",
    //   valueKey: "contactMethod",
    // },
    {
      title: "Industry",
      options: BusinessCurrentIndustry,
      changeFunction: (e) =>
        setBusinessObject({ ...businessObject, industryId: e.target.value }),
      value: businessObject.industryId,
      idKey: "industryId",
      valueKey: "industryName",
    },
    // {
    //   title: "What type of delivery vehicle will you require most often?",
    //   options: BusinessVehicleOftenRequired,
    //   changeFunction: (e) =>
    //     setBusinessObject({
    //       ...businessObject,
    //       vehicleId: e.target.value,
    //     }),
    //   value: businessObject.vehicleId,
    //   idKey: "vehicleId",
    //   valueKey: "vehicleName",
    // },
    // {
    //   title: "How many deliveries do you make on average per month?",
    //   options: BusinessAverageDeliveries,
    //   changeFunction: (e) =>
    //     setBusinessObject({
    //       ...businessObject,
    //       averageId: e.target.value,
    //     }),
    //   value: businessObject.averageId,
    //   idKey: "averageId",
    //   valueKey: "averageDeliveries",
    // },
    // {
    //   title: "How would you prefer to place an order with moovez?",
    //   options: BusinessOrderPreference,
    //   changeFunction: (e) =>
    //     setBusinessObject({
    //       ...businessObject,
    //       lalaMoveId: e.target.value,
    //     }),
    //   value: businessObject.lalaMoveId,
    //   idKey: "lalaMoveId",
    //   valueKey: "orderPreferenceWithLalamove",
    // },
    // {
    //   title: "Do you require bulk order delivery (ex. 20+ orders per day)?",
    //   options: [
    //     {
    //       id: true,
    //       value: "Yes",
    //     },
    //     {
    //       id: false,
    //       value: "No",
    //     },
    //   ],
    //   changeFunction: (e) =>
    //     setBusinessObject({
    //       ...businessObject,
    //       bulkOrderDelivery: e.target.value,
    //     }),
    //   value: businessObject.bulkOrderDelivery,
    //   idKey: "id",
    //   valueKey: "value",
    // },
    // {
    //   title:
    //     "How long is the general lead time from when the delivery order is placed to when it needs to be picked up?",
    //   options: BusinessGeneralLeadTime,
    //   changeFunction: (e) =>
    //     setBusinessObject({
    //       ...businessObject,
    //       leadTimeId: e.target.value,
    //     }),
    //   value: businessObject.leadTimeId,
    //   idKey: "leadTimeId",
    //   valueKey: "genLeadTime",
    // },
  ];

  return (
    <div className={css?.signupMainDiv}>
      <ModalComponent
        text={modalFields.text}
        openState={modalFields.open}
        closeFunction={() => {
          setModalFields({ ...modalFields, open: false });
        }}
        btnText1={modalFields.btnText1}
        btnClickFunc1={modalFields.closeFunc}
      />
      <div className={css?.mainForm}>
        {inputFields.map((section) => (
          <>
            <div className={css?.posterMainHeading}>{section.title}</div>
            {section.inputFields.map((inputParams, key) => (
              <input
                placeholder={inputParams.placeholder}
                value={inputParams.value}
                name={inputParams.name}
                onChange={inputParams.onChange}
                className={css?.fullInput}
                key={key}
              />
            ))}
          </>
        ))}
        {dropdownList.map((dropdownParams, index) => (
          <BusinessDropdown {...dropdownParams} key={index} />
        ))}
        {/* <BusinessDropdown title /> */}
        <div className={css?.signupBtnMainDiv}>
          <button className={css?.submitBtn} onClick={handleBusinessSignup}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default BusinessSignup;
