import React, { useEffect } from "react";
// importing the css
import css from "../../style/booking.module.css";
import { BiPlusMedical } from "react-icons/bi";
import { FaMinus } from "react-icons/fa";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import LightTooltip from "../Tooltip/tooltip";

const TotalHours = ({
  hours,
  bookingObject,
  min,
  max,
  increment,
  decrement,
  increment2,
}) => {
  const textArr = [
    {
      isSubbullet: false,
      text: "Select the approximate # of hours your job will take",
    },
    {
      isSubbullet: false,
      text: "All mover jobs have an hourly (work time) rate",
    },
    {
      isSubbullet: false,
      text: "We add 30 minutes travel time to all jobs within the city",
    },
    { isSubbullet: false, text: "Below is a list of our hourly rates:" },
    { isSubbullet: true, text: "1 moover: $50/hr" },
    { isSubbullet: true, text: "2 moovers: $85/hr" },
    { isSubbullet: true, text: "3 moovers: $125/hr" },
    { isSubbullet: true, text: "4 moovers: $160/hr" },
    {
      isSubbullet: false,
      text: "Example (1 mover) job: 1 hour and 15 min (work time) + 30 min (travel time) = 1 hour and 45 min*$50 = $87.50 + GST = $91.88 total.",
    },
  ];
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <div className={css?.counterMainDiv}>
      <div className={css?.Level2Heading}>
        {bookingObject?.type === "Delivery" ? (
          <>No of Hours </>
        ) : (
          <>No of Hours </>
        )}
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <LightTooltip
            title={
              <div className={css?.tooltipMainDiv}>
                {textArr.map((bullet, index) => (
                  <div
                    className={css?.tooltipBullet}
                    style={{ marginLeft: bullet.isSubbullet ? "40px" : 0 }}
                  >
                    <div className={css?.tooltipDotBox}>
                      <div className={css?.tooltipDot}>.</div>
                    </div>
                    <div className={css?.tooltipText}>{bullet.text}</div>
                  </div>
                ))}
              </div>
            }
            onClose={handleTooltipClose}
            open={open}
          >
            <img
              src="images/tooltip.svg"
              style={{ display: "inline", width: "30px", cursor: "pointer" }}
              alt=""
              onClick={handleTooltipOpen}
            />
          </LightTooltip>
        </ClickAwayListener>
      </div>
      <div className={css?.counterStrip}>
        {hours > min ? (
          <div
            onClick={() => hours > min && decrement()}
            className={css?.counterButtons}
          >
            <FaMinus />
          </div>
        ) : (
          <div
            onClick={() => hours > min && decrement()}
            style={{ backgroundColor: "#9e9ac7" }}
            className={css?.counterButtons}
          >
            <FaMinus />
          </div>
        )}
        <input
          defaultValue={1}
          value={hours}
          min={min}
          max={max}
          className={css?.counterInput}
        ></input>

        {hours < max ? (
          <div
            className={css?.counterButtons}
            onClick={() => {
              increment();
            }}
          >
            <BiPlusMedical />
          </div>
        ) : (
          <div
            className={css?.counterButtons}
            style={{ backgroundColor: "#9e9ac7" }}
            onClick={() => hours < max && increment()}
          >
            <BiPlusMedical />
          </div>
        )}
      </div>
    </div>
  );
};

export default TotalHours;
