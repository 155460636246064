import React from "react";
import Layout from "../layouts/GeneralLayout";
import Wallet from "../screens/Account/Wallet";

const MobileWallet = () => {
  return (
    <Layout>
      <Wallet />
    </Layout>
  );
};

export default MobileWallet;
