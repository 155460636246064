import React from "react";
import Layout from "../layouts/GeneralLayout";
import ConfirmEmail from "../screens/Account/ConfirmEmail";

const MobileConfirmEmailPage = () => {
  return (
    <Layout>
      <ConfirmEmail />
    </Layout>
  );
};

export default MobileConfirmEmailPage;
