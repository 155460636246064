import React from "react";
import css from "../../style/Layout.module.css";
import { FaArrowLeft, FaInfoCircle } from "react-icons/fa";
import TemporaryDrawer from "../../components/mateModel";
import { useHistory } from "react-router-dom";

const GeneralHeader = ({
  pageName,
  infoIcon,
  showBackBtn,
  headerClickFunction = () => {
    return true;
  },
}) => {
  const history = useHistory();
  return (
    <div className={css?.headerMainDiv}>
      {showBackBtn ? (
        <FaArrowLeft
          className={css?.backArrow}
          onClick={() => {
            if (history.location.pathname !== "/") history.goBack();
          }}
        />
      ) : (
        <div style={{ color: "transparent" }}>.</div>
      )}
      <div className={css?.headerInfoDiv}>
        {infoIcon ? (
          <FaInfoCircle
            style={{
              color: "var(--secondary)",
              marginRight: "0.3rem",
              fontSize: "4vh",
            }}
            onClick={headerClickFunction}
          />
        ) : (
          <></>
        )}
        {pageName ? (
          <div className={css?.pageName} onClick={headerClickFunction}>
            {pageName}
          </div>
        ) : (
          <></>
        )}
        <TemporaryDrawer />
      </div>
    </div>
  );
};

export default GeneralHeader;
