import React from "react";
import Layout from "../layouts/GeneralLayout";
import RejectSummary from "../screens/Booking/summaryRejectPage";

const MobileRejectSummary = () => {
  return (
    <Layout>
      <RejectSummary />
    </Layout>
  );
};

export default MobileRejectSummary;
