import React from "react";
import Layout from "../layouts/GeneralLayout";
import OrderSummaryScreen from "../screens/Booking/OrderSummary";

const MobileOrderSummary = () => {
  return (
    <Layout>
      <OrderSummaryScreen />
    </Layout>
  );
};

export default MobileOrderSummary;
