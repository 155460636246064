import React, { useState } from "react";
import Layout from "../layouts/GeneralLayout";
import BookingType from "../screens/Info/BookingType";
import Modal from "../components/modal";
import { useHistory } from "react-router-dom";
const cover = require("../assets/images/mooving_banner.svg").default;
const MobileMoovingPage = () => {
  const [showModal, setShow] = useState(false);
  const history = useHistory();
  const handleShowModal = () => setShow(!showModal);
  const openInfoPage = () => {
    history.push("moverInfo");
  };
  return (
    <>
      <Layout
        infoIcon={true}
        pageName="What is moving?"
        headerClickFunction={handleShowModal}
      >
        <BookingType
          cover={cover}
          title="Moving, simplified."
          p1="Get your moving job booked in a few simple steps."
          p2="Moving jobs typically take multiple trips to load & unload the vehicle. Work time can take anywhere from 1-12 hours depending on the size of the job."
          bottomButtonText={"Book now"}
          bookingType="Mooving"
        />
      </Layout>
      <Modal
        visible={showModal}
        turnItOff={() => {
          setShow(false);
        }}
        title={"Moving guidelines"}
        p1={"For a successful move, make sure you follow these requirements:"}
        list={[
          "If your load exceeds 5 items and takes over 15 minutes to load, it’s a Moving job (you’re in the right place).",
          "These jobs typically take multiple trips to load & unload the vehicle. Work time can take anywhere from 1-12 hours depending on the size of the job.",
        ]}
        p2="Common use cases:"
        list2={[
          "Apartment, condo & house moves.",
          "Office downsizing, relocation services, storage moves, dump runs, etc.",
          "Moving multiple pieces of furniture that take time to load/unload, blanket and strap down.",
        ]}
        afterList={
          <>
            <h5>Prohibited items</h5>
            <p>
              Alcohol, recreational drugs, medications, firearms, and hazardous
              or illegal items.
            </p>
          </>
        }
        greenBtnText="Got it"
        openInfoPage={openInfoPage}
      />
    </>
  );
};

export default MobileMoovingPage;
