import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import Store from "./store";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://778a73e91e424801bd0ab940011da18c@o4504448063897600.ingest.sentry.io/4504448146276352",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={Store}>
    <App />
  </Provider>,
  // </React.StrictMode>,
  document.getElementById("root")
);
