import React from "react";
import css from "../../style/becomeADriver.module.css";
import FAQAccordion from "../../components/Accordion/FAQAccordion";
function MUVFaq() {
  const FAQs = [
    {
      question: "What are wemoovez'z operation hours?",
      answer:
        "The wemoovez web app operates 24/7 all year round, so you can place an order any time. For delivery enquiries, our customer service team operates from 8am to 6pm daily, including public holidays.",
    },
    {
      question: "Can I deliver large and bulky items?",
      answer:
        "Yes, of course! MUVEZ can transport it all, from office documents to big bulky furniture, just refer to the vehicle specs page during the vehicle selection process. You will be able to find approximate sizing for each vehicle’s cargo space. Make sure to choose the vehicle that fits your delivery needs.",
    },
    {
      question: "Where does MUVEZ deliver?",
      answer:
        "MUVEZ delivers to anywhere in Alberta at the moment. We will be expanding into other Canadian cities and provinces soon so keep an eye out for that.",
    },
    {
      question: "Are there any delivery restrictions?",
      answer:
        "Extremely valuable or highly fragile items (such as a wedding cake and crystal glassware) are not recommended for delivery on the platform. MUVEZ does not want to be held responsible for ruining a wedding so we prefer items like that to be handled on your own.",
    },
    {
      question: "When will items be delivered?",
      answer:
        "Once an order has been placed for on-demand (immediate) delivery, your items will generally get picked up within 30 mins and then be transported immediately to the drop-off location. Delivery times depend on distance of delivery, road conditions and traffic.",
    },
    {
      question: "How do I know which vehicle type I need?",
      answer:
        "Max dimensions for car: 2.9 ft(L) x 3.9 ft (W) x 2.9 ft (H)\nExamples of delivery: documents, parcels, flowers & in-store pickups \n\nMax dimensions forminivan</b>: 5.9 ft (L) x 3.9 ft (W) x 3.6 ft (H)\nExamples of delivery: small furniture, household items,sports equipment & smaller appliances\n\nMax dimensions for 6.5 ft pickup truck: 6.5 ft(L) x 6.5 ft (W) x 1.6 ft (H)\nExamples of delivery: furniture, equipment, construction materials, waste & recycle items \n\n Max dimensions for 8 ft pickup truck: 8.0 ft (L) x 6.5 ft (W) x 1.6 ft (H) \n Examples of delivery: furniture, equipment, construction materials, waste & recycle items \n\n Max dimensions for cargo van:9.5 ft (L) x 5.5 ft (W) x 3.9 ft (H) \n Examples of delivery: queen sized mattress, furniture, equipment & store inventory \n Max dimensions for utility trailers (3 sizes): [4’x 8’] [5’ x 10’] [6’ x 12’]\nExamples of delivery: furniture, construction supplies,equipment & waste dumping.\n\nMax dimensions for cargo trailers (3 sizes): [4’ x8’] [5’ x 10’] [6’ x 12’]\nExamples of delivery: furniture, construction supplies, equipment & waste dumping.",
    },
    {
      question: "Can I modify my order after placing it?",
      answer:
        "No, unfortunately this feature is not yet available. You must cancel your order and place a new one. If you are having issues please contact our customer service team at +1 (780) 243 2186. If your order has already been assigned to a driver and its status is in-progress, please reach out to the driver immediately to let them know that you no longer require the delivery. This way they won’t continue towards the pickup location. You’ll also need to cancel the order in the app. You’ll be charged a $5 cancellation fee since the order was already in-progress and the driver was on the way towards you.",
    },
    {
      question:
        "I need to cancel an order. Will I be charged a cancellation fee?",
      answer:
        "You will only be charged a cancellation fee if the driver is already in-progress on his/her way towards the pickup.",
    },
    {
      question: "How do I pay for the delivery?",
      answer:
        "You will need to include a payment method before you can process your order. You only get charged upon successful completion of delivery. No cash needed.",
    },
    {
      question: "How do I sign up for a corporate account?",
      answer:
        "Please email business@weliketomuvit.com to enquire more about the setup of a corporate account. A representative will be in touch within 3 working days to answer your questions.",
    },
    {
      question: "Is there insurance coverage on my delivery?",
      answer:
        "MUVEZ provides $1,000,000 full replacement value cargo insurance coverage. If anything is lost or damaged, MUVEZ will repair, reimburse, or replace it with an item of equivalent likeness and value. Please email info@weliketomuvit.com if you have any questions or concerns about our insurance coverage.",
    },
    {
      question: "What documents are required to be a MUVEZ driver?",
      answer:
        "You will be required to provide a valid Canadian driver's licence, vehicle insurance and registration, proof of Canadian work eligibility and a background check will be performed. We need to screen all eligible drivers in order to keep our customers safe.",
    },
    {
      question: "How do I sign up to be a MUVEZ driver?",
      answer:
        'Step one is to apply. Please click on any of the buttons that read: “become a driver”, "apply to drive” or “sign up” to drive. Once you have signed up, you will be directed to our application process. Follow the required steps and upload all necessary documents. These documents are required by law. You won’t be able to accept orders until all necessary documents have been uploaded and approved by our verification team',
    },
    {
      question: "How long does the application process take?",
      answer:
        "Typically, filling out basic information (like your mobile phone number, driver’s license, and other documents) takes less than 30 minutes. Then a few more things need to happen: Vehicle Inspection: We’ll need to make sure your vehicle meets our basic requirements and fits in the category of vehicle you've applied for. Background check: Safety is our top priority, so we’ll need to run a background check to review your driving history. This can take a few days to be completed.",
    },
    {
      question: "When and where should I drive?",
      answer:
        "Normal business hours are of course quite good because most people are busy with work so could free up some time during their day by outsourcing delivery and saving on unessential trips. Weekends are a great time to target family orders. This is generally the time families will choose to move, do home improvement, declutter and clean, etc. People will gladly pay for a vehicle that show up at their doorstep and saves them a trip to the rental counter or even better does the entire delivery for them",
    },
    {
      question: "Is MUVEZ safe?",
      answer:
        "At wemoovez, we’re committed to driver and customer safety. We’ve got your back before, during, and after the ride. We've designed a review process for drivers to review the customers. Any customer that behaves in a way deemed inappropriate by our internal code of conduct will be removed from our platform indefinitely. 24/7 support: Easily contact our support team for reliable, around-the-clock help by phone or email, with any issues on and off the road.Emergency assistance: You always have the ability to contact 911 directly. Insurance: Our coverage includes liability and car insurance so we quite literally have your back — from the moment you switch into driver mode until you drop off your last delivery of the day. Learn more We do our best to create a safe work environment for every driver on our platform but we also need you to make a commitment to your own safety. Moving, lifting, carrying and delivering can be dangerous if not done properly. Safe work practices are ultimately what will reduce injuries. Slow down, be careful and stay safe out there.",
    },
    {
      question: "How do I get paid?",
      answer:
        "Your pay is based on the delivery distance and if the customer chooses to add labour. Drivers keep 100% of the tips and earn 80% of the remaining transaction. All payouts to the drivers are made through the wemoovez web app weekly, so you’ll need to add your banking info to get paid. You can also set up Express Pay to cash out when you want.",
    },
  ];
  return (
    <div className={css?.FAQPageMainDiv}>
      <div className={css?.FAQPageTopDiv}>FAQ</div>
      <div className={css?.FAQDiv}>
        {FAQs?.map((item) => (
          <FAQAccordion question={item?.question} answer={item?.answer} />
        ))}
      </div>
    </div>
  );
}

export default MUVFaq;
