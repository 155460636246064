import React, { useState } from "react";
import css from "../../style/Home2.module.css";
import { useHistory } from "react-router-dom";
import {
  CarouselProvider,
  Slider,
  Slide,
  Dot,
  DotGroup,
} from "pure-react-carousel";
import { FaCircle } from "react-icons/fa";
import "pure-react-carousel/dist/react-carousel.es.css";

function Index() {
  const history = useHistory();
  const [sliderDotBold, setSliderDotBold] = useState(0);

  const cardInfo = ["Delivery", "Moving", "Movers", "Business"];
  const carousalItems = [
    {
      img: "/images/banner1Mobile.svg",
      text: "We help you move ",
      boldText: "your things.",
    },
    {
      img: "/images/banner2.svg",
      text: "Seamless delivery for ",
      boldText: "your business.",
    },
  ];

  return (
    <div className={css?.homeMainDiv}>
      <div className={css?.cardsArea}>
        {cardInfo?.map((title, index) => (
          <div
            key={index}
            className={css?.cardMainDiv}
            onClick={() => history.push(`/${title}Next`)}
          >
            <img
              src={`/images/${title.toLowerCase()}Mobile.svg`}
              alt={title + " image"}
              className={css?.cardImage}
            />
            <div className={css?.cardTitle}>{title}</div>
          </div>
        ))}
      </div>
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={50}
        totalSlides={2}
      >
        <Slider>
          {carousalItems?.map((item, index) => (
            <Slide index={index}>
              <div className={css?.sliderMainDiv}>
                <div className={css?.textDiv}>
                  {item.text}
                  <b>{item.boldText}</b>
                </div>
                <img src={item.img} alt="" className={css?.sliderImage} />
              </div>
            </Slide>
          ))}
        </Slider>
        <span className={css?.DotGroup}>
          <DotGroup
            renderDots={(dotGroupProps) => (
              <div style={{ display: "flex" }}>
                {"- "
                  .repeat(dotGroupProps.totalSlides - 1)
                  .split("-")
                  .map((item, index) => (
                    <Dot
                      className={css?.dotcss}
                      slide={index}
                      style={{
                        color:
                          dotGroupProps.currentSlide === index
                            ? "#00a2ff"
                            : "#d6d6d6",
                      }}
                    >
                      <FaCircle />
                    </Dot>
                  ))}
              </div>
            )}
          />
        </span>
      </CarouselProvider>
    </div>
  );
}

export default Index;
