import React, { useState, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import css1 from "../../../src/style/booking.module.css";
import css from "../../../src/style/userProfile.module.css";
import { getUserProfile } from "../../store/actions/userProfileActions";
import PaymentForm from "../../components/stripe/PaymentForm";
import {
  userPaymentMethods,
  deletePaymentMethod,
} from "../../store/actions/paymentActions";
import { Typography, Modal, Box, CircularProgress } from "@material-ui/core";
import { stripeTestPromise } from "../../store/constants/baseUrl";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import * as Sentry from "@sentry/react";

const UserProfile = () => {
  const [cardHolderToggle, setcardHolderToggle] = useState(true);
  const [stripeloader, setstripeLoader] = useState(false);

  const { email } = useParams();
  const [userObj, setuserObj] = useState();
  const [paymentMethodArr, setpaymentMethodArr] = useState();
  const [cardHolderName, setcardHolderName] = useState("");

  const [addStripe, setAddStripe] = useState(false);
  // state to restrict multipel clicks
  const [buttonClicked, setButtonClicked] = useState(false);

  const handleDeleteCard = (cardId) => {
    try {
      dispatch(deletePaymentMethod(userObj?.id, cardId));
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  useEffect(() => {
    try {
      dispatch(getUserProfile(email));
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, []);

  // dispatch actions
  const dispatch = useDispatch();
  // get loginInfo from reducer
  const { userProfileInfo } = useSelector((state) => state?.userProfile);
  // getting payment info freom reducer
  const { paymentMethodInfo } = useSelector(
    (state) => state?.paymentMethodReducer
  );
  const handleClose = () => setAddStripe(false);

  // call user payment methods get API
  useEffect(() => {
    try {
      if (userProfileInfo?.id) {
        dispatch(userPaymentMethods(userProfileInfo?.id));
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [userProfileInfo]);

  // populate userObj from redux
  useEffect(() => {
    try {
      if (userProfileInfo) {
        setuserObj(userProfileInfo);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [userProfileInfo]);

  //populate paymentMethodArr from redux
  useEffect(() => {
    try {
      if (paymentMethodInfo) {
        setpaymentMethodArr(paymentMethodInfo);
        console.log("payment method", paymentMethodInfo);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [paymentMethodInfo]);

  useEffect(() => {
    try {
      let makeBtnClickable;

      if (buttonClicked) {
        makeBtnClickable = setTimeout(() => {
          setButtonClicked(false);
        }, 10000);
      }
      return () => clearTimeout(makeBtnClickable);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [buttonClicked]);

  //Modal styles
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 270,
    minheight: "100px",
    height: 170,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div className={css?.profileSettingsMainDiv}>
      <Modal
        open={addStripe}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Postal Code and CVC verified.
          </Typography>

          <div style={{ marginLeft: "12%" }}>
            <div
              className={css1?.roundBtns}
              onClick={async () => {
                handleClose();
                setstripeLoader(false);
              }}
            >
              Ok
            </div>
          </div>
        </Box>
      </Modal>
      <div
        className={css?.profileSettingsInnerMain}
        style={{ padding: "20px" }}
      >
        <div className={css?.accountDetails}>
          <div className={css?.accountDetailsHeading}>payments</div>
          <div className={css?.paymentSec}>
            <div className={css?.paymentHeading}>Current Payment Method</div>
            <div className={css?.paymentText}>
              Manage your credit cards for future payments and wemoovez
              requests.
            </div>
            {paymentMethodArr?.length > 0 ? (
              <div className={css?.paymentTable}>
                <div className={css?.paymentTableHeading}>
                  <div className={css?.paymentTableHeadingCard}>Card</div>
                  <div className={css?.paymentTableHeadingDelete}>Delete</div>
                </div>
                {paymentMethodArr?.map((paymentMethod) => (
                  <div className={css?.paymentTableLine}>
                    <div className={css?.paymentTableLineCard}>
                      {paymentMethod?.stripeCardLastFourDigits}
                    </div>
                    <div className={css?.paymentTableLineDelete}>
                      <span
                        className={css?.paymentTableLineDeleteIcon}
                        onClick={() =>
                          handleDeleteCard(paymentMethod?.stripeCardId)
                        }
                      >
                        <FaTrashAlt />
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
          <div className={css?.cardMainBody}>
            <div className={css?.paymentHeading}>Save Card for Future Use</div>
            <div className={css?.cardMainBodyCred}>
              {/* card holder's name */}
              <div className={css?.accountDetailsBoxInputBox2}>
                {cardHolderToggle ? (
                  <span className={css?.accountDetailsBoxInputBoxLabel}>
                    {cardHolderName ? cardHolderName : "Card Holder's Name"}
                  </span>
                ) : (
                  <input
                    value={cardHolderName}
                    onChange={(e) => setcardHolderName(e.target.value)}
                    className={css?.accountDetailsBoxInputBoxInput}
                    placeholder="Card Holder's Name"
                  ></input>
                )}
                <span
                  className={css?.accountDetailsBoxInputBoxEditIcon}
                  onClick={() => setcardHolderToggle(!cardHolderToggle)}
                >
                  <FaEdit />
                </span>
              </div>
              <Elements stripe={stripeTestPromise}>
                <PaymentForm
                  userId={userProfileInfo?.id}
                  setAddStripe={setAddStripe}
                  setstripeLoader={setstripeLoader}
                  submitWrapper={(cb, e) => {
                    e.preventDefault();
                    if (!buttonClicked) {
                      setButtonClicked(true);
                      cb(e);
                    }
                  }}
                />
              </Elements>

              {stripeloader ? (
                <div style={{ textAlignLast: "center", paddingTop: "0%" }}>
                  <Box>
                    <CircularProgress color="secondary" />
                  </Box>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
