import {
  GET_BOOKING_FAIL,
  GET_BOOKING_SUCCESS,
  GET_BOOKING_REQUEST,
} from "../constants/bookingConstants";
import axios from "axios";
import baseUrl from "../constants/baseUrl";
import * as Sentry from "@sentry/react";

// post booking action
// this action is called in delivery pages
export const postBooking = async (bookingData, setBookingObj) => {
  try {
    console.log("posting booking for the first time======", bookingData);
    const { data } = await axios.post(`${baseUrl}BookingsAPI`, bookingData);
    if (data) setBookingObj(null, data?.data, "9");
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
  }
};

// post booking action
// this action is called in trip pages
export const cancelBooking =
  (bookingId, userId, singleTrip, history) => async (dispatch) => {
    try {
      console.log("booking cancel hay", bookingId);
      const res = await axios.get(
        `${baseUrl}BookingsAPI/cancelBooking?bookingId=${bookingId}`
      );
      if (singleTrip) {
        history.push(`/myTrips/${userId}`);
      } else {
        const { data } = await axios.get(
          `${baseUrl}ProfileDetailAPI/myTrips?id=${userId}`
        );
        console.log("trip data from api", data);
        dispatch({
          type: "GET_TRIP_SUCCESS",
          payload: data,
        });
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };
// update booking action
// this action is called in multiple pages
export const updateBooking = async (bookingId, bookingObj, setBookingObj) => {
  try {
    const { data } = await axios.put(
      `${baseUrl}BookingsAPI/${bookingId}`,
      bookingObj
    );
    if (data) setBookingObj(null, data, "8");
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
  }
};

// get booking total action
export const getBookingTotal = async (bookingObj, setTotal) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}BookingsAPI/GetTotalBookingModel`,
      bookingObj
    );
    setTotal(data);
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
  }
};

// get booking action
// this action is called in multiple pages
export const getSingleBooking = (bookingId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_BOOKING_REQUEST,
    });
    const { data } = await axios.get(`${baseUrl}BookingsAPI/${bookingId}`);
    console.log("booking data from api", data);
    dispatch({
      type: GET_BOOKING_SUCCESS,
      payload: data,
    });
  } catch (error) {
    Sentry.captureException(error);
    console.log(error);
    dispatch({
      type: GET_BOOKING_FAIL,
      payload: "booking API Failed,Please try again!",
    });
  }
};

// get booking action
// this action is called in multiple pages
export const getBookingForUser = (userId, BookingType) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${baseUrl}BookingsAPI/bookingInprogress?userId=${userId}&type=${BookingType}`
    );
    console.log("booking data of user from api", data);
    if (data) {
      dispatch({
        type: GET_BOOKING_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    Sentry.captureException(error);
    console.log(error);
  }
};

// post bookingFile action
// this action is called in delivery pages
export const postBookingFiles = async (
  bookingId,
  bookingFiles,
  setLoader,
  handleUpdateBookingObject
) => {
  try {
    await axios.post(
      `${baseUrl}BookingsAPI/bookingFiles?id=${bookingId}`,
      bookingFiles
    );
    const { data } = await axios.get(`${baseUrl}BookingsAPI/${bookingId}`);
    if (
      data?.bookingFiles &&
      Array.isArray(data?.bookingFiles) &&
      data?.bookingFiles?.length > 0
    ) {
      handleUpdateBookingObject("bookingFiles", data?.bookingFiles, "19");
    }
    setLoader(false);
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
  }
};

// Delete booking File action
export const deleteBookingFile = (bookingId) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}SaveImageAPI/DeleteBookingFile?id=${bookingId}`
    );
    console.log("booking files data from api", data);
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
  }
};

// delete stop action
// this action is called in delivery pages
export const deleteStop = (stopId) => async (dispatch) => {
  try {
    const { data } = await axios.delete(`${baseUrl}DestinationsAPI/${stopId}`);
    console.log("deleted stop data", data);
    // dispatch({
    //   type: GET_BOOKING_SUCCESS,
    //   payload: data,
    // });
  } catch (error) {
    Sentry.captureException(error);
    console.log(error);
  }
};
// get move places action
// this action is called in delivery pages
export const getMovePlaces = (setMovePlaces) => async (dispatch) => {
  try {
    const { data } = await axios.get(`${baseUrl}MoveTypesAPI/GetMovePlaces`);
    console.log("Move places API result", data);
    setMovePlaces(data);
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
  }
};
// get move size action
// this action is called in delivery pages
export const getMoveSize = (movePlaceId, setMoveSize) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${baseUrl}MoveTypesAPI/GetMoveTypes?movePlaceId=${movePlaceId}`
    );
    console.log("Move size API result", data);
    setMoveSize(data);
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
  }
};
