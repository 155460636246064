import React, { useEffect } from "react";
import { populateUser } from "../store/actions/loginActions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import css from "../style/Layout.module.css";
import GeneralFooter from "./footer/GeneralFooter";
import GeneralHeader from "./headers/GeneralHeader";
import * as Sentry from "@sentry/react";

const Layout = ({
  header = true,
  footer = true,
  children,
  pageName = "",
  infoIcon = false,
  showBackBtn = true,
  headerClickFunction,
}) => {
  // dispatch actions
  const dispatch = useDispatch();
  // history for routing
  const history = useHistory();
  // get object from the local storage and populate values
  useEffect(() => {
    try {
      window.scrollTo(0, 0);
      const tempUser = JSON.parse(localStorage.getItem("wemoovezUserProfile"));
      console.log("User in LS", tempUser);
      if (tempUser) {
        dispatch(populateUser(tempUser, history));
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, []);
  return (
    <div
      style={{
        height: "100vh",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      {header ? (
        <GeneralHeader
          pageName={pageName}
          infoIcon={infoIcon}
          headerClickFunction={headerClickFunction}
          showBackBtn={showBackBtn}
        />
      ) : (
        <></>
      )}
      <div className={css?.allContentDiv}>{children}</div>
      {footer !== false && <GeneralFooter />}
    </div>
  );
};

export default Layout;
