import {
  FEED_BACK_REQUEST,
  FEED_BACK_SUCCESS,
  FEED_BACK_FAIL,
} from "../constants/feedBackConstants";
import axios from "axios";
import baseUrl from "../constants/baseUrl";
import * as Sentry from "@sentry/react";

// feedback action
// this action is called in feedback pages
// it shows feedbacks
export const getAllFeedBacks = async (feedBackObj) => {
  try {
    // dispatch({
    // type: FEED_BACK_REQUEST,
    // });

    const { data } = await axios.post(
      `${baseUrl}FeedBackFromUser`,
      feedBackObj
    );
    console.log("feedback data from api", data);
    // dispatch({
    // type: FEED_BACK_SUCCESS,
    // payload: data,
    // });
  } catch (error) {
    Sentry.captureException(error);
    console.log(error);
    // dispatch({
    // type: FEED_BACK_FAIL,
    // payload: "feedback API Failed,Please try again!",
    // });
  }
};
