import React, { useEffect } from "react";
import Wallet from "../../assets/images/icon/wallet.svg";
import Trips from "../../assets/images/icon/trips.svg";
import Help from "../../assets/images/icon/help.svg";
import { useSelector, useDispatch } from "react-redux";
import { getUserProfile } from "../../store/actions/userProfileActions";
import { profileImageURL } from "../../store/constants/baseUrl";
import css from "../../style/profileHome.module.css";
import { useParams, useHistory } from "react-router-dom";
import {
  FaCog,
  FaEnvelope,
  FaGift,
  FaStar,
  FaStarHalf,
  FaUserShield,
} from "react-icons/fa";
import * as Sentry from "@sentry/react";
const UserProfile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { email } = useParams();
  const { userProfileInfo, userProfileLoading, userProfileError } = useSelector(
    (state) => state?.userProfile
  );
  const topButtons = [
    { text: "Contact us", icon: Help, path: "/contactUs" },
    { text: "Wallet", icon: Wallet, path: `/wallet/${email}` },
    { text: "Trips", icon: Trips, path: `/myTrips/${userProfileInfo?.id}` },
  ];

  const bottomList = [
    { text: "Message", Icon: () => <FaEnvelope />, path: "/contactUs" },
    { text: "Send a gift", Icon: () => <FaGift />, path: "/" },
    { text: "Setting", Icon: () => <FaCog />, path: `/settings/${email}` },
    {
      text: "Refer a friend",
      Icon: () => <FaCog />,
      path: `/referAFriend/${userProfileInfo?.id}`,
    },
    {
      text: "Become a mover with moovez",
      Icon: () => <FaUserShield />,
      path: "/signup",
    },
  ];

  const handleProfileImageUrl = () => {
    try {
      if (userProfileInfo?.profileDisplayImageUrl)
        return profileImageURL + userProfileInfo?.profileDisplayImageUrl;
      return `/images/${
        userProfileInfo?.gender === "woman" ? "woman" : "man"
      }-default.png`;
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const ReturnStars = ({ rating }) => {
    const stars = [];
    for (let counter = 1; counter <= rating; counter++) stars.push(<FaStar />);
    if (rating - stars.length > 0) stars.push(<FaStarHalf />);
    return (
      <div className={css?.ratingMainDiv}>
        <div className={css?.ratingDiv}>
          {stars.map((Star) => (
            <Star style={{ color: "gold" }} />
          ))}
        </div>
        {rating}
      </div>
    );
  };
  const TopButton = ({ text, icon, path }) => {
    return (
      <div className={css?.topButtonDiv} onClick={() => history.push(path)}>
        <img src={icon} alt={text} className={css?.topCardImage} />
        <div className={css?.topButtonCSS}>{text}</div>
      </div>
    );
  };
  const BottomList = ({ text, Icon, path }) => {
    return (
      <div className={css?.bottomListDiv} onClick={() => history.push(path)}>
        <span className={css?.listIcon}>
          <Icon />
        </span>
        {text}
      </div>
    );
  };

  useEffect(() => {
    try {
      if (email) dispatch(getUserProfile(email));
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, []);

  return (
    <>
      {userProfileLoading ? (
        <></>
      ) : userProfileError ? (
        userProfileError
      ) : (
        <div className={css?.profileHMainDiv}>
          <img
            src={handleProfileImageUrl()}
            alt="Your "
            className={css?.profileImage}
          />
          <div
            className={css?.userNameDiv}
          >{`${userProfileInfo?.firstName} ${userProfileInfo?.lastName}`}</div>
          {userProfileInfo?.userRatings ? (
            <div className={css?.ratingMainDiv}>
              <ReturnStars rating={userProfileInfo?.userRatings} />
            </div>
          ) : (
            <></>
          )}
          <div className={css?.topButtonsMainDiv}>
            {topButtons.map((buttonProps) => (
              <TopButton {...buttonProps} />
            ))}
          </div>
          <div style={{ width: "100%" }}>
            {bottomList.map((listParams) => (
              <BottomList {...listParams} />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default UserProfile;
