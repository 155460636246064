import React, { useState } from "react";
import css from "../../style/forgotPassword.module.css";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  forgotPassword,
  verifyCodeAction,
  resetPassword,
} from "../../store/actions/forgotPasswordActions";
import {
  FaEnvelope,
  FaFileCode,
  FaTimesCircle,
  FaUndoAlt,
} from "react-icons/fa";
import * as Sentry from "@sentry/react";

const ForgotPassword = () => {
  // status
  const [status, setstatus] = useState("forgot");
  // verification code
  const [verificationcode, setverificationcode] = useState();
  //history for routing
  const history = useHistory();
  // dispatch actions
  const dispatch = useDispatch();
  // error from API
  const [errorFromAPI, seterrorFromAPI] = useState("");

  // email
  const [email, setemail] = useState("");
  const [emailActive, setemailActive] = useState(false);
  //password
  const [password, setpassword] = useState("");
  const [passwordStrength, setpasswordStrength] = useState(0);
  //confirmPass
  const [confirmPassword, setconfirmPassword] = useState("");
  // check password strength
  const handleSetPassword = (e) => {
    try {
      let strengthLevel = 0;
      const passArray = [...e];
      setpassword(e);
      if (e !== 0) {
        const hasLowerCase = passArray?.filter(
          (letter) => letter >= "a" && letter <= "z"
        );
        const hasUpperCase = passArray?.filter(
          (letter) => letter >= "A" && letter <= "Z"
        );
        const hasNumber = passArray?.filter(
          (letter) => letter >= "0" && letter <= "9"
        );
        const hasSC1 = passArray?.filter(
          (letter) => letter >= "!" && letter <= "/"
        );
        const hasSC2 = passArray?.filter(
          (letter) => letter >= ":" && letter <= "@"
        );
        const hasSC3 = passArray?.filter(
          (letter) => letter >= "[" && letter <= "`"
        );
        const hasSC4 = passArray?.filter(
          (letter) => letter >= "{" && letter <= "~"
        );
        if (hasLowerCase?.length > 0) {
          strengthLevel = strengthLevel + 20;
        }
        if (hasUpperCase?.length > 0) {
          strengthLevel = strengthLevel + 20;
        }
        if (hasNumber?.length > 0) {
          strengthLevel = strengthLevel + 20;
        }
        if (
          hasSC1?.length > 0 ||
          hasSC2?.length > 0 ||
          hasSC3?.length > 0 ||
          hasSC4?.length > 0
        ) {
          strengthLevel = strengthLevel + 20;
        }
        if (e?.length > 5) {
          strengthLevel = strengthLevel + 20;
        }
      }
      setpasswordStrength(strengthLevel);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };
  // forgot password
  const handleForgotPassword = () => {
    try {
      if (email) {
        dispatch(forgotPassword(email, setstatus, seterrorFromAPI));
      } else {
        alert("Please fill all the fields");
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };
  const handleVerifyCode = () => {
    try {
      if (verificationcode) {
        dispatch(
          verifyCodeAction(email, verificationcode, setstatus, seterrorFromAPI)
        );
      } else {
        alert("Please enter the verification code.");
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const handleResetPassword = () => {
    try {
      if (password && confirmPassword) {
        if (password === confirmPassword) {
          if (passwordStrength === 100) {
            dispatch(
              resetPassword(
                email,
                password,
                history,
                setstatus,
                seterrorFromAPI
              )
            );
          } else {
            alert(
              "Password must be of at least 6 characters, with both upper and lower chase letters,numbers and special characters."
            );
          }
        } else {
          alert("Passwords do not match");
        }
      } else {
        alert("Please fill all the fields.");
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };
  return (
    <div className={css?.signup_page}>
      <div className={css?.inner_content}>
        {status === "forgot" ? (
          <>
            <div class={css?.RegisterMainHeading}>Forgot Password?</div>
            <div className={css?.dash_with_title}>
              Don't have an account?{" "}
              <span
                className={css?.signupButton}
                onClick={() => history.push("/Signup")}
              >
                Sign up
              </span>
            </div>

            <div className={css?.dash_with_title}>
              Enter your email address you're using for your account below and
              we will send you a password reset link.
            </div>
            <div class={css?.sign_up_form}>
              <div class={css?.fullInputleft}>
                <span className={css?.textIconsFull}>
                  <FaEnvelope />
                </span>
                <input
                  placeholder="Email"
                  className={css?.inputFieldFull}
                  value={email}
                  onFocus={() => setemailActive(true)}
                  onChange={(e) => setemail(e?.target?.value)}
                />
                {emailActive && !email && (
                  <span className={css?.crossIconFull}>
                    <FaTimesCircle />
                  </span>
                )}
              </div>
            </div>
            <div style={{ color: "red" }}>
              {errorFromAPI ? errorFromAPI : ""}
            </div>
            <div className={css?.BottomButtons2}>
              <div className={css?.dash_with_title} style={{ width: "40%" }}>
                Back to{" "}
                <span
                  className={css?.signupButton}
                  onClick={() => history.push("/Login")}
                >
                  Sign in
                </span>
              </div>
              <button
                onClick={handleForgotPassword}
                className={css?.submitButton}
              >
                Request Password Reset
              </button>
            </div>
          </>
        ) : status === "verify" ? (
          <>
            <div class={css?.RegisterMainHeading}>Forgot Password?</div>
            <div className={css?.dash_with_title}>
              Not your account?{" "}
              <span
                className={css?.signupButton}
                onClick={() => {
                  setstatus("forgot");
                  setverificationcode("");
                  setemail("");
                  seterrorFromAPI("");
                }}
              >
                Change email
              </span>
            </div>

            <div className={css?.dash_with_title}>
              A verification code has been sent to {email}.
            </div>
            <div class={css?.sign_up_form}>
              <div class={css?.fullInputleft}>
                <span className={css?.textIconsFull}>
                  <FaFileCode />
                </span>
                <input
                  placeholder="Code"
                  value={verificationcode ? verificationcode : ""}
                  className={css?.inputFieldFull}
                  onChange={(e) => setverificationcode(e?.target?.value)}
                />
              </div>
            </div>
            <div style={{ color: "red" }}>
              {errorFromAPI ? errorFromAPI : ""}
            </div>
            <div className={css?.BottomButtons2}>
              <div className={css?.dash_with_title} style={{ width: "40%" }}>
                Back to{" "}
                <span
                  className={css?.signupButton}
                  onClick={() => history.push("/wemoovezLogin")}
                >
                  Sign in
                </span>
              </div>
              <button
                onClick={handleVerifyCode}
                className={css?.submitButton}
                style={{ backgroundColor: "var(--primary)", color: "white" }}
              >
                Verify
              </button>
            </div>
          </>
        ) : (
          <>
            <div class={css?.RegisterMainHeading}>Reset Password</div>
            <div class={css?.sign_up_form}>
              <div class={css?.fullInputleft}>
                <span className={css?.textIconsFull}>
                  <FaUndoAlt />
                </span>
                <input
                  placeholder="Password"
                  type="password"
                  value={password}
                  className={css?.inputFieldFull}
                  onChange={(e) => handleSetPassword(e?.target?.value)}
                />
              </div>
              <div class={css?.fullInputleft}>
                <span className={css?.textIconsFull}>
                  <FaUndoAlt />
                </span>
                <input
                  placeholder="Confirm password"
                  type="password"
                  value={confirmPassword}
                  className={css?.inputFieldFull}
                  onChange={(e) => setconfirmPassword(e?.target?.value)}
                />
              </div>
            </div>
            <div style={{ color: "red" }}>
              {errorFromAPI ? errorFromAPI : ""}
            </div>
            <div className={css?.BottomButtons2}>
              <div
                className={css?.dash_with_title}
                style={{ width: "40%", color: "transparent" }}
              >
                .
              </div>
              <button
                onClick={handleResetPassword}
                className={css?.submitButton}
                style={{ backgroundColor: "var(--primary)", color: "white" }}
              >
                Reset Password
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
