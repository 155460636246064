import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { AiOutlineDownload } from "react-icons/ai";
import {
  FaBriefcase,
  FaCalendarDay,
  FaChevronCircleLeft,
  FaClock,
  FaMapMarkedAlt,
  FaMapPin,
  FaTruckMoving,
  FaWallet,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import MapComponent from "../../components/Booking/Map";
import {
  cancelBooking,
  getSingleBooking,
} from "../../store/actions/bookingActions";
import { bookingFilesImageURL } from "../../store/constants/baseUrl";
import css from "../../style/singleTrip.module.css";
import * as Sentry from "@sentry/react";

const SingleTrip = () => {
  const [bookingObj, setbookingObj] = useState();
  //   stops length
  const [stopLength, setstopLength] = useState(0);

  // dispatch actions
  const dispatch = useDispatch();
  // history for routing
  const history = useHistory();
  // getting params
  const { userId, bookingId } = useParams();

  // getting booking details from reducer
  const { bookingInfo } = useSelector((state) => state.bookingReducer);

  // getting booking
  useEffect(() => {
    try {
      dispatch(getSingleBooking(bookingId));
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, []);

  //populating booking Obj
  useEffect(() => {
    try {
      if (bookingInfo) {
        setbookingObj(bookingInfo);
        setstopLength(bookingInfo?.stops?.length);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [bookingInfo]);

  const handleCancelBooking = () => {
    try {
      dispatch(cancelBooking(bookingId, userId, true, history));
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };
  const handleEditBooking = () => {
    try {
      if (
        ["Delivery", "Mooving", "Moovers", "business"].includes(
          bookingInfo.type
        )
      ) {
        localStorage.setItem("currentBookingType", bookingInfo.type);
        localStorage.setItem(
          `wemoovez${bookingInfo.type}Object`,
          JSON.stringify(bookingInfo)
        );
        history.push({
          pathname: `/booking`,
          state: { bookingType: bookingInfo.type },
        });
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  return (
    <>
      {bookingObj ? (
        <div className={css?.tripsMainDiv}>
          <div className={css?.tripsLeftDiv}>
            <div
              className={css?.tripsHeading}
              onClick={() => history.push(`/myTrips/${userId}`)}
            >
              <span className={css?.backButton}>
                <FaChevronCircleLeft />
              </span>
              my trips
            </div>
          </div>
          <div className={css?.tripsRightDiv}>
            <div className={css?.tripsTopLine}>
              <div className={css?.tripsHeading}>today's trip details</div>
            </div>
            <div className={css?.TripCard}>
              <div className={css?.TripMap}>
                <MapComponent bookingObject={bookingObj} />
              </div>
              <div className={css?.TripCardWhite}>
                <div className={css?.TripCardFirstLine}>
                  <div className={css?.TripCardFirstLineBox}>
                    <div className={css?.TripCardFirstLineBoxLabel}>
                      <span className={css?.TripCardFirstLineBoxLabelIcon}>
                        <FaCalendarDay />
                      </span>
                      <span className={css?.TripCardFirstLineBoxLabelText}>
                        Date
                      </span>
                    </div>
                    <div className={css?.TripCardFirstLineBoxData}>
                      {bookingObj?.deliveryDate?.split(" ")[0]}
                    </div>
                  </div>
                  <div className={css?.TripCardFirstLineBox}>
                    <div className={css?.TripCardFirstLineBoxLabel}>
                      <span className={css?.TripCardFirstLineBoxLabelIcon}>
                        <FaClock />
                      </span>
                      <span className={css?.TripCardFirstLineBoxLabelText}>
                        Time
                      </span>
                    </div>
                    <div className={css?.TripCardFirstLineBoxData}>
                      {bookingObj?.exactTime}
                    </div>
                  </div>
                  <div className={css?.TripCardFirstLineBox}>
                    <div className={css?.TripCardFirstLineBoxLabel}>
                      <span className={css?.TripCardFirstLineBoxLabelIcon}>
                        <FaMapMarkedAlt />
                      </span>
                      <span className={css?.TripCardFirstLineBoxLabelText}>
                        Pickup
                      </span>
                    </div>
                    <div className={css?.TripCardFirstLineBoxData}>
                      {bookingObj?.pickupLocation}
                    </div>
                  </div>
                  <div className={css?.TripCardFirstLineBox}>
                    <div className={css?.TripCardFirstLineBoxLabel}>
                      <span className={css?.TripCardFirstLineBoxLabelIcon}>
                        <FaMapMarkedAlt />
                      </span>
                      <span className={css?.TripCardFirstLineBoxLabelText}>
                        DropOff
                      </span>
                    </div>
                    <div className={css?.TripCardFirstLineBoxData}>
                      {bookingObj?.dropoffLocation}
                    </div>
                  </div>
                </div>
                <div className={css?.TripCardSecondLine}>
                  <div className={css?.TripCardSecondLineBox}>
                    <div className={css?.TripCardSecondLineBoxLabel}>
                      <span className={css?.TripCardSecondLineBoxLabelIcon}>
                        <FaMapMarkedAlt />
                      </span>
                      <span className={css?.TripCardSecondLineBoxLabelText}>
                        Pickup-Address
                      </span>
                    </div>
                    <div className={css?.TripCardSecondLineBoxData}>
                      {bookingObj?.pickupLocation}
                    </div>
                  </div>
                  <div className={css?.TripCardSecondLineBox}>
                    <div className={css?.TripCardSecondLineBoxLabel}>
                      <span className={css?.TripCardSecondLineBoxLabelIcon}>
                        <FaMapMarkedAlt />
                      </span>
                      <span className={css?.TripCardSecondLineBoxLabelText}>
                        Drop-off-Address
                      </span>
                    </div>
                    <div className={css?.TripCardSecondLineBoxData}>
                      {bookingObj?.dropoffLocation}
                    </div>
                  </div>
                </div>
                <div className={css?.TripCardThirdLine}>
                  <div className={css?.TripCardThirdLineAddress}>
                    <div className={css?.TripCardThirdLineAddressIcon}>
                      <FaMapMarkedAlt />
                    </div>
                    <div
                      className={css?.TripCardThirdLineAddressLabel}
                    >{`A instructions:`}</div>
                    <div className={css?.TripCardThirdLineAddressText}>
                      {bookingObj?.pickUpInstructions}
                    </div>
                  </div>
                  {bookingObj?.stops?.map((stop, index) => (
                    <div className={css?.TripCardThirdLineAddress}>
                      <div className={css?.TripCardThirdLineAddressIcon}>
                        <FaMapPin />
                      </div>
                      <div
                        className={css?.TripCardThirdLineAddressLabel}
                      >{`${String.fromCharCode(
                        index + 66
                      )} instructions:`}</div>
                      <div className={css?.TripCardThirdLineAddressText}>
                        {/* {bookingObj?.pickUpInstructions} */}
                        {bookingObj?.stops[index]?.instructions}
                      </div>
                    </div>
                  ))}
                  <div className={css?.TripCardThirdLineAddress}>
                    <div className={css?.TripCardThirdLineAddressIcon}>
                      <FaMapMarkedAlt />
                    </div>
                    <div className={css?.TripCardThirdLineAddressLabel}>
                      {`
                  ${String.fromCharCode(stopLength + 66)}
                   instructions:`}
                    </div>
                    <div className={css?.TripCardThirdLineAddressText}>
                      {bookingObj?.dropOffInstructions}
                    </div>
                  </div>
                </div>

                {/* =========================================== */}

                {/* <div className={css?.TripCardFourthLineFileOne}> 
                      {bookingObj?.bookingFiles?.map((file, index) =>
                        file?.type?.split("/")[0] === "image" ? (
                          <div
                          style={{
                            display: "flex",
                            justifyContent: "left",
                            alignItems: "center",
                            marginLeft: 22,
                            width: "90%",
                            flexDirection: "column",
                          }}
                          // onclick={() => {filterImageFiles(i?.id)}}
                        >
                          <AiOutlineDownload
                            style={{
                              color: "red",
                              paddingLeft: 15,
                              paddingBottom: 10,
                              cursor: "pointer",
                            }}
                            size={30}
                            onClick={() => {
                            }} />
                            </a>
                        </div>
                        ) : null
                      )}
                </div> */}

                <div className={css?.TripCardFourthLineFileOne}>
                  {bookingObj?.bookingFiles?.map((file, index) =>
                    file?.type?.split("/")[0] === "image" ? (
                      <div
                        style={
                          {
                            // display: "flex",
                            // justifyContent: "left",
                            // alignItems: "center",
                            // marginLeft: 22,
                            // width: "90%",
                            // flexDirection: "column",
                          }
                        }
                        // onclick={() => {filterImageFiles(i?.id)}}
                        className={css?.TripCardFourthLineFileInner}
                      >
                        <img
                          src={`${bookingFilesImageURL}${file?.fileUrl}`}
                          alt={`image #${index}`}
                          style={{ height: 70, width: 90 }}
                        />
                        <p>{file?.fileUrl}</p>
                        <a
                          href={`${bookingFilesImageURL}${file?.fileUrl}`}
                          download
                          style={{ height: 50, width: 50 }}
                        >
                          <AiOutlineDownload
                            style={{
                              color: "red",
                              paddingLeft: 15,
                              paddingBottom: 10,
                              cursor: "pointer",
                              marginLeft: 25,
                            }}
                            size={30}
                            onClick={() => {}}
                          />
                        </a>
                      </div>
                    ) : null
                  )}
                </div>

                {/* ========================================== */}
                <div className={css?.TripCardFourthLine}>
                  <div className={css?.TripCardFourthLineBox}>
                    <div className={css?.TripCardFourthLineBoxLabel}>
                      <span className={css?.TripCardFourthLineBoxLabelIcon}>
                        <FaBriefcase />
                      </span>
                      <span className={css?.TripCardFourthLineBoxLabelText}>
                        Labour
                      </span>
                    </div>
                    <div className={css?.TripCardFourthLineBoxData}>
                      {bookingObj?.labourNeeded
                        ? bookingObj?.labourNeeded
                        : "No Labour"}
                    </div>
                  </div>
                  <div className={css?.TripCardFourthLineBox}>
                    <div className={css?.TripCardFourthLineBoxLabel}>
                      <span className={css?.TripCardFourthLineBoxLabelIcon}>
                        <FaTruckMoving />
                      </span>
                      <span className={css?.TripCardFourthLineBoxLabelText}>
                        Vehicle size
                      </span>
                    </div>
                    <div className={css?.TripCardFourthLineBoxData}>
                      {bookingObj?.vehicle?.title}
                    </div>
                  </div>
                  <div className={css?.TripCardFourthLineBox}>
                    <div className={css?.TripCardFourthLineBoxLabel}>
                      <span className={css?.TripCardFourthLineBoxLabelIcon}>
                        <FaWallet />
                      </span>
                      <span className={css?.TripCardFourthLineBoxLabelText}>
                        Est. Cost
                      </span>
                    </div>
                    <div className={css?.TripCardFourthLineBoxData}>
                      {bookingObj?.total}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button className={css?.cancelPickup} onClick={handleCancelBooking}>
              Cancel Pickup
            </button>
            {/*<button className={css?.EditTripBtn} onClick={handleEditBooking}>
              Edit Trip
                      </button>*/}
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "40vh",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default SingleTrip;
