import React from "react";
// importing the css
import css from "../../style/booking.module.css";
//Modal Imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import RoundButton from "../Booking/RoundButton";
import CircularProgress from "@mui/material/CircularProgress";

//Modal styles
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 250,
  bgcolor: "background.paper",
  p: 4,
  color: "var(--text)",
  outline: "none",
};

const ModalComponent = ({
  text,
  openState,
  closeFunction,
  btnText1,
  btnClickFunc1,
  btnText2,
  btnClickFunc2,
  loader,
}) => {
  return (
    <Modal
      open={openState}
      onClose={closeFunction}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ border: "none" }}
    >
      <Box sx={style}>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {text}
        </Typography>
        <div
          style={{
            marginTop: "12%",
            display: "flex",
            jusftifyContent: "space-between",
          }}
        >
          {btnText1 && (
            <div className={css?.roundBtn4} onClick={btnClickFunc1}>
              {btnText1}
            </div>
          )}
          {btnText2 && (
            <div className={css?.roundBtn4} onClick={btnClickFunc2}>
              {btnText2}
            </div>
          )}
          {loader && (
            <div style={{ textAlignLast: "center", paddingTop: "10%" }}>
              <Box>
                <CircularProgress color="secondary" />
              </Box>
            </div>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default ModalComponent;
