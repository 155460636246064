import React from "react";
import css from "../../style/deliveryInfo.module.css";
import { useHistory } from "react-router";

const S6HowDoesItWork = () => {
  const history = useHistory();
  return (
    <div className={css?.s6Md}>
      <img src="/images/step3.png" className={css?.s6L} />
      <div className={css?.s6R}>
        <div className={css?.s6RSubDiv}>
          <h3 className={css?.s6RH}>How does it work?</h3>
          <p className={css?.s6RT}>
            In a few simple steps, you can book a moover. Delivery has never
            been so easy.
          </p>
          <div
            className={css?.s6RBtn}
            onClick={() => history.push("/moverInfo")}
          >
            Find out how
          </div>
        </div>
      </div>
    </div>
  );
};

export default S6HowDoesItWork;
