import React, { useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useDispatch } from "react-redux";
import axios from "axios";
import { userPaymentMethods } from "../../store/actions/paymentActions";
import * as Sentry from "@sentry/react";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "black",
      fontWeight: "500",
      fontFamily: "Roboto,Open Sans,Segeo UI,sans-serif",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#fce883" },
      ":-placeholder": { color: "#87bbfd" },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "red",
    },
  },
};

const PaymentForm = ({
  userId,
  setAddStripe,
  setstripeLoader,
  submitWrapper,
}) => {
  // stripe setup
  const [paymentSuccess, setpaymentSuccess] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const { error, token } = await stripe.createToken(
        elements.getElement(CardElement)
      );
      console.log("token response", token);

      if (!error) {
        const { id } = token;
        setstripeLoader(true);
        const { data } = await axios.post(
          `BookingsAPI/AddingStripeToUserProfile?userId=${userId}&token=${id}`
        );
        console.log("successful Payment with data", data.statusCode);
        if (data.statusCode === 200) {
          dispatch(userPaymentMethods(userId));
          console.log("successful Payment ho geii ha ", data);
          setAddStripe(true);
        }
      } else {
        console.log("payment error", error.message);
        setAddStripe(false);
        setstripeLoader(false);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };
  return (
    <div>
      {paymentSuccess ? (
        <div>Payment successful</div>
      ) : (
        <form
          onSubmit={(e) =>
            submitWrapper ? submitWrapper(handleSubmit, e) : handleSubmit(e)
          }
          style={{
            display: "flex",
            justifyContent: "flex-end",
            flexWrap: "wrap",
          }}
        >
          <fieldset style={{ width: "100%", margin: 0, padding: 0 }}>
            <div
              style={{
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "10px",
              }}
            >
              <CardElement options={CARD_OPTIONS} />
            </div>
          </fieldset>
          <input
            type="submit"
            value="Save Card"
            style={{
              backgroundColor: "var(--primary)",
              color: "#fff",
              fontWeight: "700",
              fontSize: "15px",
              padding: "10px",
              borderRadius: "5px",
              margin: "20px 0 0 auto",
              outline: "none",
              border: "none",
            }}
          ></input>
        </form>
      )}
    </div>
  );
};

export default PaymentForm;
