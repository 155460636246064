import React, { useState } from "react";
import Layout from "../layouts/GeneralLayout";
import BookingType from "../screens/Info/BookingType";
import Modal from "../components/modal";
import { useHistory } from "react-router-dom";
const cover = require("../assets/images/banner2.svg").default;
const MobileMoovingPage = () => {
  const [showModal, setShow] = useState(false);
  const history = useHistory();
  const handleShowModal = () => setShow(!showModal);
  const openInfoPage = () => {
    history.push("deliveryInfo");
  };
  return (
    <>
      <Layout
        infoIcon={true}
        pageName="What is a delivery?"
        headerClickFunction={handleShowModal}
      >
        <BookingType
          baseUrl={"/mobile"}
          cover={cover}
          title="Schedule a delivery"
          p1="Save your back, let us get it for you."
          p2="Delivery jobs typically involve a small number of items, usually 1-5, and can be completed swiftly. Loading and unloading the vehicle should typically take no more than 15-20 minutes."
          bottomButtonText={"Schedule now"}
          bookingType="Delivery"
        />
      </Layout>
      <Modal
        visible={showModal}
        turnItOff={() => {
          setShow(false);
        }}
        title={"Delivery  guidelines"}
        p1={"For a successful delivery, make sure you follow these guidelines:"}
        list={[
          "1-5 items",
          "These jobs are quick. It should take approximately 15-20 minutes to load & unload the vehicle.",
        ]}
        p2={"Common use cases:"}
        list2={[
          "marketplace, deliveries , curb-side/In-store pick-ups, construction material delivery, etc.",
        ]}
        afterList={
          <>
            <h5>Prohibited items</h5>
            <p>
              Alcohol, recreational drugs, medications, firearms, and hazardous
              or illegal items.
            </p>
          </>
        }
        greenBtnText="Got it"
        openInfoPage={openInfoPage}
      />
    </>
  );
};

export default MobileMoovingPage;
