import React from "react";
// importing the css
import css from "../../style/booking.module.css";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import LightTooltip from "../Tooltip/tooltip";

const TotalConfirm = ({ bookingObject }) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <div className={css?.totalConfirmMainDIv}>
      <div className={css?.totalHeading}>
        Total
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <LightTooltip
            title={
              <div className={css?.priceDivConfirmation}>
                {["Delivery", "Business"].includes(bookingObject?.type) ? (
                  <div className={css?.priceDivTopConfirmation}>
                    <div className={css?.priceBreakdownLineConfirmation}>
                      <span className={css?.priceBreakdownLeftWord}>
                        Base fare
                      </span>
                      <span className={css?.priceBreakdownRightWord}>
                        ${bookingObject?.bookingTotalModel?.baseFare}
                      </span>
                    </div>
                    <div className={css?.priceBreakdownLineConfirmation}>
                      <span className={css?.priceBreakdownLeftWord}>
                        Distance
                      </span>
                      <span className={css?.priceBreakdownRightWord}>
                        {bookingObject?.bookingTotalModel?.totalDistance}
                      </span>
                    </div>
                    <div className={css?.priceBreakdownLineConfirmation}>
                      <span className={css?.priceBreakdownLeftWord}>Labor</span>
                      <span className={css?.priceBreakdownRightWord}>
                        ${bookingObject?.bookingTotalModel?.labourSurcharge}
                      </span>
                    </div>
                    <div className={css?.priceBreakdownLineConfirmation}>
                      <span className={css?.priceBreakdownLeftWord}>
                        Extra moover
                      </span>
                      <span className={css?.priceBreakdownRightWord}>
                        $
                        {
                          bookingObject?.bookingTotalModel
                            ?.delivery_ExtraMooverCharge
                        }
                      </span>
                    </div>
                    <div className={css?.priceBreakdownLineConfirmation}>
                      <span className={css?.priceBreakdownLeftWord}>
                        Subtotal
                      </span>
                      <span className={css?.priceBreakdownRightWord}>
                        ${bookingObject?.bookingTotalModel?.TotalChargeBTax}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className={css?.priceDivTopConfirmation}>
                    <div className={css?.priceBreakdownLineConfirmation}>
                      <span className={css?.priceBreakdownLeftWord}>
                        Hourly rate
                      </span>
                      <span className={css?.priceBreakdownRightWord}>
                        ${bookingObject?.bookingTotalModel?.hourlyRate}
                      </span>
                    </div>
                    <div className={css?.priceBreakdownLineConfirmation}>
                      <span className={css?.priceBreakdownLeftWord}>
                        Work time (hrs)
                      </span>
                      <span className={css?.priceBreakdownRightWord}>
                        {bookingObject?.bookingTotalModel?.workTime}
                      </span>
                    </div>
                    <div className={css?.priceBreakdownLineConfirmation}>
                      <span className={css?.priceBreakdownLeftWord}>
                        Travel time (hrs)
                      </span>
                      <span className={css?.priceBreakdownRightWord}>
                        {bookingObject?.bookingTotalModel?.travelTime}
                      </span>
                    </div>
                    <div className={css?.priceBreakdownLineConfirmation}>
                      <span className={css?.priceBreakdownLeftWord}>
                        Est. subtotal
                      </span>
                      <span className={css?.priceBreakdownRightWord}>
                        {bookingObject?.bookingTotalModel?.totalChargeBTax}
                      </span>
                    </div>
                  </div>
                )}
                <div
                  className={css?.priceDivMid}
                  style={{ borderBottom: "1px solid var(--secondary)" }}
                >
                  <div className={css?.priceBreakdownLineConfirmation}>
                    <span className={css?.priceBreakdownLeftWord}>
                      Service Fee
                    </span>
                    <span className={css?.priceBreakdownRightWord}>
                      ${bookingObject?.bookingTotalModel?.totalServiceFee}
                    </span>
                  </div>
                  <div className={css?.priceBreakdownLineConfirmation}>
                    <span className={css?.priceBreakdownLeftWord}>
                      GST ({" "}
                      {bookingObject?.bookingTotalModel?.totalTaxGstpstrate}% )
                    </span>
                    <span className={css?.priceBreakdownRightWord}>
                      ${bookingObject?.bookingTotalModel?.gstandPst}
                    </span>
                  </div>
                  <div className={css?.priceBreakdownLineConfirmation}>
                    <span className={css?.priceBreakdownLeftWord}>
                      Discount
                    </span>
                    <span className={css?.priceBreakdownRightWord}>
                      ${bookingObject?.bookingTotalModel?.discount}
                    </span>
                  </div>
                </div>
                <div
                  className={css?.priceDivBottom}
                  style={{ fontWeight: "500", color: "white" }}
                >
                  <div className={css?.priceBreakdownLineConfirmation}>
                    <span className={css?.priceBreakdownLeftWord}>
                      Est. total:
                    </span>
                    <span className={css?.priceBreakdownRightWord}>
                      ${bookingObject?.bookingTotalModel?.totalCharge}
                    </span>
                  </div>
                </div>
              </div>
            }
            onClose={handleTooltipClose}
            open={open}
          >
            <img
              src="images/info2.svg"
              style={{
                display: "inline",
                marginLeft: "7px",
                width: "25px",
                cursor: "pointer",
              }}
              alt=""
              onClick={handleTooltipOpen}
            />
          </LightTooltip>
        </ClickAwayListener>
      </div>
      <div className={css?.totalText}>
        {"$ " + bookingObject?.bookingTotalModel?.totalCharge + " CAD"}
      </div>
    </div>
  );
};

export default TotalConfirm;
