import * as Sentry from "@sentry/react";

export const updateUserCardStatus = (hasCard) => {
  try {
    let user = localStorage.getItem("wemoovezUserProfile");
    if (!user) return;
    user = JSON.parse(user);
    user = { ...user, hasCard };
    localStorage.setItem("wemoovezUserProfile", JSON.stringify(user));
  } catch (error) {
    Sentry.captureException(error);
    console.log(error);
  }
};

export const generateBookingLogs = (name, value, triggeredBy, type) => {
  const log = type + "Log";
  let logFile = localStorage.getItem(log);
  if (logFile) {
    logFile = JSON.parse(logFile);
    if (Array.isArray(logFile) && logFile.length < 1000)
      localStorage.setItem(
        log,
        JSON.stringify([...logFile, { name, value, triggeredBy }])
      );
    else
      localStorage.setItem(log, JSON.stringify([{ name, value, triggeredBy }]));
  } else {
    localStorage.setItem(log, JSON.stringify([{ name, value, triggeredBy }]));
  }
};

export const handleValidatePhoneNumber = (e, setphoneNo) => {
  try {
    let number = e?.target?.value;
    let numArr = [...number];
    if (numArr?.length < 13) {
      let newNum = "";
      for (let i = 0; i < numArr?.length; i++) {
        if (numArr[i] >= "0" && numArr[i] <= "9") {
          newNum = `${newNum}${i == 3 || i == 7 ? "-" : ""}${numArr[i]}`;
        } else if (numArr[i] === "-" && (i === 3 || i === 7)) {
          newNum = newNum + numArr[i];
        } else {
          setphoneNo(newNum);
          return null;
        }
      }
      setphoneNo(newNum);
    }
  } catch (error) {
    Sentry.captureException(error);
    console.log(error);
  }
};
