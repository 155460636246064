import { Person } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { BiLogIn } from "react-icons/bi";
import { HiLogin } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import css from "../style/Layout.module.css";
import { logout } from "../store/actions/loginActions";
import { profileImageURL } from "../store/constants/baseUrl";
import { FaLockOpen, FaPowerOff, FaTruck, FaUserAlt } from "react-icons/fa";
import * as Sentry from "@sentry/react";

export default function TemporaryDrawer() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = React.useState({
    right: false,
  });

  const { loginInfo } = useSelector((state) => state.loginReducer);

  const toggleDrawer = (anchor, open) => (event) => {
    try {
      if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const tripText = (text, num = 11) => {
    try {
      if (text) {
        return "Hi, " + text?.length > num + 3
          ? text.slice(0, num) + "..."
          : text;
      }
      return "Welcome to moovez!";
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const historyPush = (url) => {
    try {
      history.push(url);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const iconStyle = {
    color: "rgb(38,25,117)",
    paddingRight: 8,
    fontSize: "28px",
  };

  const DrawerItemsListIfLoggedIn = [
    {
      Icon: () => <FaUserAlt style={iconStyle} />,
      clickFunction: () => {
        historyPush(`/UserProfile/${loginInfo?.email}`);
      },
      text: "Profile",
    },
    {
      Icon: () => <FaTruck style={iconStyle} />,
      clickFunction: () => {
        historyPush(`/MyTrips/${loginInfo?.id}`);
      },
      text: "My Trip",
    },
    {
      Icon: () => <FaLockOpen style={iconStyle} />,
      clickFunction: () => {
        historyPush(`/ResetPassword/${loginInfo?.email}`);
      },
      text: "Change Password",
    },
    {
      Icon: () => <FaPowerOff style={iconStyle} />,
      clickFunction: () => {
        dispatch(logout());
        historyPush(`/login`);
      },
      text: "Logout",
    },
  ];

  const DrawerItemsListIfNotLoggedIn = [
    {
      Icon: () => <BiLogIn style={iconStyle} />,
      clickFunction: () => {
        historyPush(`/login`);
      },
      text: "Log in",
    },
    {
      Icon: () => <HiLogin style={iconStyle} />,
      clickFunction: () => {
        historyPush(`/signup`);
      },
      text: "Sign up",
    },
  ];

  const ListItemLayout = ({ Icon, clickFuction, text }) => (
    <List>
      <ListItem disablePadding>
        <ListItemButton onClick={clickFuction}>
          <Icon />
          <ListItemText sx={{ color: "rgb(38,25,117)" }}>{text}</ListItemText>
        </ListItemButton>
      </ListItem>
    </List>
  );

  const list = (anchor) => (
    <Box
      role="presentation"
      style={{ width: 250 }}
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List style={{ height: 34 }}>
        <ListItem
          sx={{
            pl: 2,
            pr: 2,
          }}
          disablePadding
        >
          <ListItemText
            disableTypography
            primary={
              <Typography
                type="body1"
                style={{
                  color: "var(--primary)",
                  fontSize: 16,
                  fontWeight: 500,
                }}
              >
                {tripText(loginInfo?.firstName)}
              </Typography>
            }
          />
          <ListItemIcon sx={{ display: "flex", justifyContent: "flex-end" }}>
            <ClearIcon fontSize="medium" />
          </ListItemIcon>
        </ListItem>
      </List>
      <Divider />
      {loginInfo ? (
        <>
          {DrawerItemsListIfLoggedIn?.map((item, index) => (
            <ListItemLayout
              Icon={item.Icon}
              clickFuction={item.clickFunction}
              text={item.text}
              key={index}
            />
          ))}
        </>
      ) : (
        <>
          {DrawerItemsListIfNotLoggedIn?.map((item, index) => (
            <ListItemLayout
              Icon={item.Icon}
              clickFuction={item.clickFunction}
              text={item.text}
              key={index}
            />
          ))}
        </>
      )}
    </Box>
  );

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          {!loginInfo?.profileDisplayImageUrl ? (
            <Person
              style={{
                color: "white",
                fontSize: "6vh",
                margin: "0 10px",
              }}
              onClick={toggleDrawer(anchor, true)}
            />
          ) : (
            <div onClick={toggleDrawer(anchor, true)}>
              <img
                src={`${profileImageURL}${loginInfo?.profileDisplayImageUrl}`}
                alt="profile"
                className={css?.profilePicture}
              />
            </div>
          )}

          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
