import React from "react";
import Layout from "../layouts/GeneralLayout";
import FAQs from "../screens/Info/FAQs";

const MobileFAQsPage = () => {
  return (
    <Layout>
      <FAQs />
    </Layout>
  );
};

export default MobileFAQsPage;
