import React from "react";
// importing the css
import css from "../../style/booking.module.css";

const RoundButton = ({ text, clickFunction }) => {
  return (
    <div
      className={css?.roundBtn}
      style={{ margin: "10px 0" }}
      onClick={clickFunction}
    >
      {text}
    </div>
  );
};

export default RoundButton;
