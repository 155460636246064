import React, { useState, useEffect, useRef } from "react";
import css from "../../style/signupStyles.module.css";
import { FaChevronDown } from "react-icons/fa";

const BusinessDropdown = ({
  title,
  options = [],
  changeFunction,
  idKey,
  valueKey,
}) => {
  const [toggleDD, setToggleDD] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const menuRef = useRef();
  useEffect(() => {
    const closeDDMenuEvent = (e) => {
      if (!menuRef?.current?.contains(e.target)) {
        setToggleDD(false);
      }
    };
    document.addEventListener("click", closeDDMenuEvent);

    return () => {
      document.addEventListener("click", closeDDMenuEvent);
    };
  }, []);
  return (
    <>
      {options.length ? (
        <div className={css?.dropdownMainDiv}>
          <div
            className={css?.dropdownTextDiv}
            onClick={() => setToggleDD(!toggleDD)}
            ref={menuRef}
            title={title}
          >
            <div className={css?.DDText}>
              {selectedItem ? selectedItem : title}
            </div>
            <FaChevronDown />
          </div>
          <div
            className={css?.DDOptions}
            style={{ display: toggleDD ? "block" : "none" }}
          >
            {" "}
            {options.map((option) => (
              <div
                onClick={() => {
                  changeFunction(option[idKey]);
                  setSelectedItem(option[valueKey]);
                }}
                className={css?.DDOption}
                style={{
                  backgroundColor:
                    option[valueKey] === selectedItem
                      ? "var(--secondary)"
                      : "white",
                }}
              >
                {option[valueKey]}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default BusinessDropdown;
