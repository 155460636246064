import Css from "./modal.module.css";

const Modal = ({
  title,
  p1,
  p2,
  p3,
  list,
  list2,
  visible,
  turnItOff,
  greenBtnText,
  afterList,
  openInfoPage,
}) => (
  <div
    className={Css?.modal}
    id="more_detail"
    tabIndex="-1"
    aria-labelledby="more_detailLabel"
    aria-modal="true"
    role="dialog"
    style={{ display: visible ? "block" : "none" }}
  >
    <div className={Css?.modal_dialog_centered}>
      <div className={Css?.modal_content}>
        <div className={Css?.modal_header}>
          <h5 className={Css?.modal_title} id="more_detailLabel">
            {title}
          </h5>
        </div>
        <div className={Css?.modal_Body}>
          <p>{p1}</p>
          <ul className={Css.list_unstyled}>
            {list?.map((it) => (
              <li key={it}>{it}</li>
            ))}
          </ul>
          {Boolean(p2) && <p>{p2}</p>}
          {Boolean(p3) && <p>{p3}</p>}
          <ul className={Css.list_unstyled}>
            {list2 ? list2?.map((it) => <li key={it}>{it}</li>) : <></>}
          </ul>
          {Boolean(afterList) && <>{afterList}</>}
        </div>
        <div className={Css.modal_footer}>
          <button type="button" className={Css?.leftBtn} onClick={turnItOff}>
            {greenBtnText}
          </button>
          <button
            type="button"
            className={Css?.rightBtn}
            onClick={openInfoPage}
          >
            More info
          </button>
        </div>
      </div>
    </div>
  </div>
);
export default Modal;
