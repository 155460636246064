import React from "react";
import { FaCheck } from "react-icons/fa";
import css from "../../style/thankyou.module.css";

const ThankYou = () => {
  return (
    <div className={css?.thankyouMainDiv}>
      <div className={css?.thankyouCheck}>
        <FaCheck />
      </div>
      <div className={css?.thankyouHeading}>Thank you!</div>
      <div className={css?.thankyouText}>
        Your submission has been received. One of our Partnership Coordinators
        will get back to you very soon.
      </div>
    </div>
  );
};

export default ThankYou;
