import * as React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import css from "../../style/howMovEzWorks.module.css";
import { FaPlusSquare } from "react-icons/fa";

export default function SimpleAccordion({ question, answer }) {
  return (
    <div>
      <Accordion className={css?.accordionMainDiv}>
        <AccordionSummary
          expandIcon={<FaPlusSquare />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className={css?.FAQQuestionText}>{question}</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={css?.FAQAnswerText}>{answer}</div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
