import React from "react";
import CSS from "./booking.module.css";
import { useHistory } from "react-router-dom";

const BookingType = ({
  cover,
  p1,
  p2,
  p3,
  title,
  bottomButtonText,
  bookingType,
}) => {
  const history = useHistory();
  return (
    <div className={CSS?.wraper}>
      <div className={CSS?.typeContainer}>
        <div className={CSS?.inner_banner}>
          <img src={cover} alt="" className={CSS.img_fluid} />
        </div>
        <div className={CSS?.category_desc}>
          <h4>{title}</h4>
          <p>{p1}</p>
          <p>{p2}</p>
          {Boolean(p3) && <p>{p3}</p>}
          <div
            onClick={() => {
              localStorage.setItem("currentBookingType", bookingType);
              history.push(
                bookingType === "Business" ? "/business" : "/booking"
              );
            }}
            className={CSS?.green_btn2}
          >
            {bottomButtonText}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingType;
