import React from "react";
import css from "../../style/myTrips.module.css";
import { useHistory } from "react-router-dom";

const TripCard = ({ data }) => {
  // history for routing
  const history = useHistory();
  const userInfo = JSON.parse(localStorage.getItem("wemoovezUserProfile"));

  return (
    <div className={css?.cardMainDiv}>
      <div className={css?.cardLeftDiv}>
        <div className={css?.cardStatus}>{data?.status}</div>
        <div className={css?.cardDate}>
          {data?.deliveryDate?.split(" ")[0] + " " + data?.exactTime}
        </div>
        <div className={css?.cardPickup}>{data?.pickupLocation}</div>
        {data?.stops?.map((stop) => (
          <div className={css?.cardPickup}>{stop?.stop}</div>
        ))}
        <div className={css?.cardPickup}>{data?.dropoffLocation}</div>
        <div className={css?.vehicleName}>{data?.vehicle?.title}</div>
      </div>
      <div className={css?.cardRightDiv}>
        <div className={css?.totalBill}>
          {data?.bookingTotalModel?.totalCharge}CAD
        </div>
        <div className={css?.buttonsDiv}>
          <div
            className={css?.changePaymentBtn}
            onClick={() => history?.push(`/wallet/${userInfo?.email}`)}
          >
            change payment method
          </div>
          <div
            className={css?.viewDetailsBtn}
            onClick={() =>
              history?.push(`/singleTrip/${userInfo?.id}/${data?.id}`)
            }
          >
            view details
          </div>
        </div>
      </div>
    </div>
  );
};

export default TripCard;
