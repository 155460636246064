import React, { useState, useEffect } from "react";
import css from "../../style/loginStyles.module.css";
import { useDispatch, useSelector } from "react-redux";
import Moveez from "../../assets/images/MoovezDark.svg";
import { referAFriend } from "../../store/actions/friendReferralActions";
import ModalComponent from "../../components/Modals/Modal";
import { FaEnvelope } from "react-icons/fa";
import * as Sentry from "@sentry/react";

const ForgotPassword = () => {
  // friend email for referrals
  const [referralEmail, setReferralEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setsuccess] = useState("");
  const [loader, setLoader] = useState();
  const [openReferralModal, setOpenReferralModal] = useState(false);
  // state to restrict multipel clicks
  const [buttonClicked, setButtonClicked] = useState(false);

  // getting user details from reducer
  const { loginInfo } = useSelector((state) => state.loginReducer);
  const dispatch = useDispatch();
  //Validating Email
  function ValidateEmail(mail) {
    try {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return true;
      }
      return false;
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }
  const handleReferAFriend = () => {
    try {
      if (ValidateEmail(referralEmail)) {
        setError("");
        if (!buttonClicked) {
          setButtonClicked(true);
          dispatch(
            referAFriend(
              referralEmail,
              loginInfo?.id,
              setError,
              setsuccess,
              setLoader,
              setOpenReferralModal
            )
          );
          setLoader(true);
          setReferralEmail("");
        }
      } else {
        setsuccess("");
        setError("Please enter a valid email");
        setOpenReferralModal(true);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  useEffect(() => {
    try {
      let makeBtnClickable;

      if (buttonClicked) {
        makeBtnClickable = setTimeout(() => {
          setButtonClicked(false);
        }, 10000);
      }
      return () => clearTimeout(makeBtnClickable);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [buttonClicked]);

  return (
    <div className={css?.loginMainDiv}>
      <ModalComponent
        text={success ? success : error}
        openState={openReferralModal}
        closeFunction={() => setOpenReferralModal(!openReferralModal)}
        btnText1={"OK"}
        btnClickFunc1={() => setOpenReferralModal(!openReferralModal)}
      />
      <img src={Moveez} alt="Moveez Logo" style={{ width: "75%" }} />
      <div className={css?.posterMainHeading}>Refer a friend</div>
      <div className={css?.posterText}>
        You have earned {loginInfo?.credit ?? 0}$ so far. Invite your friends to
        order through wemoovez, using your unique link, and you will each
        receive a $5 credit when thry place their first order
      </div>
      <div class={css?.inputBox}>
        <span>
          <FaEnvelope style={{ color: "gray" }} />
        </span>
        <input
          placeholder="Enter email"
          className={css?.fullInput}
          value={referralEmail}
          onChange={(e) => setReferralEmail(e?.target?.value)}
        />
      </div>
      <div className={css?.signupBtnMainDiv}>
        <button onClick={handleReferAFriend} className={css?.signupBtn}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default ForgotPassword;
