import React from "react";
import Layout from "../layouts/GeneralLayout";
import DeliveryInfo from "../screens/Info/DeliveryInfo";

const DeliveryInfoPage = () => {
  return (
    <Layout>
      <DeliveryInfo />
    </Layout>
  );
};

export default DeliveryInfoPage;
