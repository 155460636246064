import React, { useEffect, useState, useRef } from "react";
// importing the css
import css from "../../style/booking.module.css";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import * as Sentry from "@sentry/react";

const MapComponent = ({ bookingObject, handleUpdateBookingObject }) => {
  //   adding maps
  const [mapRef, _] = useState(useRef(null));
  //pickup coordinates
  const [pickupCoordinates, setpickupCoordinates] = useState({
    lat: null,
    lng: null,
  });
  //dropoffcoordinates
  const [dropOffcoordinates, setdropOffcoordinates] = useState({
    lat: null,
    lng: null,
  });

  const handleSetCoordinates = async (value, isPickup) => {
    try {
      if (value) {
        const results = await geocodeByAddress(value);
        const latLng = await getLatLng(results[0]);
        isPickup ? setpickupCoordinates(latLng) : setdropOffcoordinates(latLng);
      } else {
        isPickup ? setpickupCoordinates(null) : setdropOffcoordinates(null);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };
  useEffect(() => {
    try {
      handleSetCoordinates(bookingObject?.pickupLocation, true);
      handleSetCoordinates(bookingObject?.dropoffLocation, false);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [bookingObject?.pickupLocation, bookingObject?.dropoffLocation]);

  useEffect(() => {
    try {
      let google = window?.google;
      if (!google) return;
      let map = mapRef.current;

      const myLatlng = new google.maps.LatLng({
        lat: pickupCoordinates?.lat,
        lng: pickupCoordinates?.lng,
      });
      const dropOffmyLatlng = new google.maps.LatLng(
        dropOffcoordinates?.lat,
        dropOffcoordinates?.lng
      );
      const mapOptions = {
        zoom: 12,
        center: new google.maps.LatLng(51.0543976, -114.0838157),
        scrollwheel: false,
        zoomControl: true,
      };
      if (pickupCoordinates?.lat) {
        mapOptions.center = myLatlng;
      } else if (dropOffcoordinates?.lat) {
        mapOptions.center = dropOffmyLatlng;
      }

      map = mapOptions ? new google.maps.Map(map, mapOptions) : null;

      //Function for directions

      //For Directions from pickup to DropOff
      const mapLocation = () => {
        try {
          let directionsDisplay;
          let directionsService = new google.maps.DirectionsService();
          //Initializes directionDisplay object and calls the calcRoute func
          directionsDisplay = new google.maps.DirectionsRenderer({
            polylineOptions: {
              strokeColor: "#332a88",
              strokeWeight: 5,
              preserveViewport: true,
              suppressMarkers: true,
            },
          });
          directionsDisplay.setMap(map);
          //creating a waypoint for google direction service for creating a Stop
          var stops = [];
          for (let i = 0; i < bookingObject?.stops?.length; i++) {
            const address = bookingObject?.stops[i];
            if (address !== "") {
              stops.push({
                location: {
                  lat: parseFloat(address?.lat),
                  lng: parseFloat(address?.long),
                },
                stopover: true,
              });
            }
          }

          const request = {
            origin: pickupCoordinates,
            destination: dropOffcoordinates,
            waypoints: stops,
            optimizeWaypoints: true,
            travelMode: google.maps.TravelMode.DRIVING,
          };
          if (!dropOffcoordinates && stops.length)
            request.destination = request.waypoints.pop().location;

          directionsService.route(request, function (response, status) {
            if (status === google.maps.DirectionsStatus.OK) {
              directionsDisplay.setDirections(response);
              directionsDisplay.setMap(map);
              getResponseFromCalcRoute(response);
            } else {
              console.log("Directions not available", status);
            }
          });
        } catch (error) {
          Sentry.captureException(error);
          console.log(error);
        }
      };

      const getResponseFromCalcRoute = (resp) => {
        try {
          let routeDistance = 0;
          let routeDuration = 0;
          resp.routes[0].legs?.forEach((journey) => {
            routeDistance = routeDistance + journey?.distance?.value;
            routeDuration = routeDuration + journey?.duration?.value;
          });
          if (!pickupCoordinates?.lat && !dropOffcoordinates?.lat) return;
          if (
            pickupCoordinates.lat !== bookingObject.pickupLatitude ||
            pickupCoordinates.lng !== bookingObject.pickupLongitude ||
            dropOffcoordinates.lat !== bookingObject.dropoffLatitude ||
            dropOffcoordinates.lng !== bookingObject.dropoffLongitude ||
            Math.ceil(routeDistance / 1000) !== bookingObject.distanceInKm ||
            Math.ceil(routeDuration / 60) !== bookingObject.durationInMins
          )
            handleUpdateBookingObject(
              null,
              {
                ...bookingObject,
                pickupLatitude: pickupCoordinates.lat,
                pickupLongitude: pickupCoordinates.lng,
                dropoffLatitude: dropOffcoordinates.lat,
                dropoffLongitude: dropOffcoordinates.lng,
                distanceInKm: Math.ceil(routeDistance / 1000),
                durationInMins: Math.ceil(routeDuration / 60),
              },
              "15"
            );
        } catch (error) {
          Sentry.captureException(error);
          console.log(error);
        }
      };
      if (
        (pickupCoordinates?.lat && dropOffcoordinates?.lat) ||
        ((pickupCoordinates?.lat || dropOffcoordinates?.lat) &&
          bookingObject?.stops?.length)
      ) {
        setTimeout(mapLocation(), 100);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [
    bookingObject?.pickupLocation,
    bookingObject?.dropoffLocation,
    dropOffcoordinates,
    pickupCoordinates,
    bookingObject?.stops,
  ]);

  return (
    <>
      <div className={css?.mapDiv} ref={mapRef} />
    </>
  );
};

export default MapComponent;
