import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import css from "../../../src/style/userProfile.module.css";
import ModalComponent from "../../components/Modals/Modal.js";
import {
  getUserProfile,
  postProfileImage,
  postUserProfile,
  deleteProfile,
} from "../../store/actions/userProfileActions";
import { profileImageURL } from "../../store/constants/baseUrl";
import { FaEdit } from "react-icons/fa";
import * as Sentry from "@sentry/react";

const UserProfile = () => {
  const [error, seterror] = useState("");
  // state to display success message
  const [success, setsuccess] = useState("");
  //bring labels into text
  const [firstNameToggle, setfirstNameToggle] = useState(true);
  const [lastNameToggle, setlastNameToggle] = useState(true);
  const [emailToggle, setemailToggle] = useState(true);
  const [contactNoToggle, setcontactNoToggle] = useState(true);
  const [DOBToggle, setDOBToggle] = useState(false);

  const { email } = useParams();
  const history = useHistory();
  // userProfileObj
  const [userObj, setuserObj] = useState();
  const [loader, setLoader] = useState();

  const handleUpdateProfile = () => {
    setDOBToggle(false);
    dispatch(postUserProfile(userObj));
  };
  //change screen
  const [changeScreen, setchangeScreen] = useState(0);

  //upload user profile image
  const handleMediaFileUpload = (e) => {
    try {
      console.log(e?.target?.files);
      const formData = new FormData();
      const file = e.target.files[0];
      formData.append(file?.type, file);

      dispatch(postProfileImage(userObj?.id, userObj?.email, formData));
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  // call user profile get API
  useEffect(() => {
    try {
      dispatch(getUserProfile(email));
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, []);

  // dispatch actions
  const dispatch = useDispatch();
  // get loginInfo from reducer
  const { userProfileInfo } = useSelector((state) => state?.userProfile);

  // getting user details from reducer
  const { loginInfo } = useSelector((state) => state.loginReducer);

  // open delete profile modal
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [userInfo, setuserInfo] = useState();

  // populate userObj from redux
  useEffect(() => {
    try {
      if (userProfileInfo) {
        setuserObj(userProfileInfo);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [userProfileInfo]);

  // function to set userinfo
  useEffect(() => {
    try {
      if (loginInfo) {
        console.log("User is logged in", loginInfo);
        setuserInfo(loginInfo);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [loginInfo]);

  const handleDeleteProfile = () => {
    try {
      dispatch(deleteProfile(userProfileInfo?.id, history, seterror));
      setOpenDeleteModal(false);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };
  //Modal styles
  return (
    <div className={css?.profileSettingsMainDiv}>
      <ModalComponent
        text={"Are you sure you want to delete your profile?"}
        openState={openDeleteModal}
        closeFunction={() => setOpenDeleteModal(false)}
        btnText1={"Yes, I am sure"}
        btnClickFunc1={handleDeleteProfile}
        btnText2={"Cancel"}
        btnClickFunc2={() => setOpenDeleteModal(false)}
      />
      <div className={css?.profileSettingsInnerMain}>
        <div className={css?.accountDetails}>
          <div className={css?.accountDetailsHeading}>profile settings</div>
          {/* profile image */}
          <div className={css?.accountDetailsBox}>
            <div
              className={css?.accountDetailsBoxText}
              style={{ color: "var(--primary)" }}
            >
              Profile image
            </div>
            <div className={css?.accountDetailsBoxInputBoxOne}>
              <span className={css?.accountDetailsBoxInputBoxLabelOne}>
                {userObj?.profileDisplayImageUrl && (
                  <img
                    src={`${profileImageURL}${userObj?.profileDisplayImageUrl}`}
                    alt="profile"
                    className={css?.accountDetailsBoxInputBoxLabelOneImage}
                  />
                )}
              </span>
              <input
                style={{ width: "100%" }}
                id="upload-profile-image"
                type="file"
                accept="image/*"
                onChange={(e) => handleMediaFileUpload(e)}
              ></input>
            </div>
          </div>
          {/* first name */}
          <div className={css?.accountDetailsBox}>
            <div
              className={css?.accountDetailsBoxText}
              style={{ color: "var(--primary)" }}
            >
              Your first name*
            </div>
            <div className={css?.accountDetailsBoxInputBox}>
              {firstNameToggle ? (
                <span className={css?.accountDetailsBoxInputBoxLabel}>
                  {userObj?.firstName}
                </span>
              ) : (
                <input
                  value={userObj?.firstName}
                  onChange={(e) =>
                    setuserObj({ ...userObj, firstName: e.target.value })
                  }
                  className={css?.accountDetailsBoxInputBoxInput}
                ></input>
              )}
              <span
                className={css?.accountDetailsBoxInputBoxEditIcon}
                onClick={() => setfirstNameToggle(!firstNameToggle)}
              >
                <FaEdit />
              </span>
            </div>
          </div>
          {/* last name */}
          <div className={css?.accountDetailsBox}>
            <div
              className={css?.accountDetailsBoxText}
              style={{ color: "var(--primary)" }}
            >
              Your last name*
            </div>
            <div className={css?.accountDetailsBoxInputBox}>
              {lastNameToggle ? (
                <span className={css?.accountDetailsBoxInputBoxLabel}>
                  {userObj?.lastName}
                </span>
              ) : (
                <input
                  value={userObj?.lastName}
                  onChange={(e) =>
                    setuserObj({
                      ...userObj,
                      lastName: e.target.value,
                    })
                  }
                  className={css?.accountDetailsBoxInputBoxInput}
                ></input>
              )}
              <span
                className={css?.accountDetailsBoxInputBoxEditIcon}
                onClick={() => setlastNameToggle(!lastNameToggle)}
              >
                <FaEdit />
              </span>
            </div>
          </div>
          {/* email */}
          <div className={css?.accountDetailsBox}>
            <div
              className={css?.accountDetailsBoxText}
              style={{ color: "var(--primary)" }}
            >
              Contact Email (Not log in email!)*
            </div>
            <div className={css?.accountDetailsBoxInputBox}>
              {emailToggle ? (
                <span className={css?.accountDetailsBoxInputBoxLabel}>
                  {userObj?.secondaryEmail}
                </span>
              ) : (
                <input
                  value={userObj?.secondaryEmail}
                  onChange={(e) =>
                    setuserObj({
                      ...userObj,
                      secondaryEmail: e.target.value,
                    })
                  }
                  className={css?.accountDetailsBoxInputBoxInput}
                ></input>
              )}
              <span
                className={css?.accountDetailsBoxInputBoxEditIcon}
                onClick={() => setemailToggle(!emailToggle)}
              >
                <FaEdit />
              </span>
            </div>
          </div>
          {/* contactNo */}
          <div className={css?.accountDetailsBox}>
            <div
              className={css?.accountDetailsBoxText}
              style={{ color: "var(--primary)" }}
            >
              Your phone number*
            </div>
            <div className={css?.accountDetailsBoxInputBox}>
              {contactNoToggle ? (
                <span className={css?.accountDetailsBoxInputBoxLabel}>
                  {userObj?.phone}
                </span>
              ) : (
                <input
                  value={userObj?.phone}
                  onChange={(e) =>
                    setuserObj({ ...userObj, phone: e.target.value })
                  }
                  className={css?.accountDetailsBoxInputBoxInput}
                ></input>
              )}
              <span
                className={css?.accountDetailsBoxInputBoxEditIcon}
                onClick={() => setcontactNoToggle(!contactNoToggle)}
              >
                <FaEdit />
              </span>
            </div>
          </div>
          {/* Date Of Birth */}
          <div className={css?.accountDetailsBox}>
            <div className={css?.accountDetailsBoxText}>Date of Birth</div>
            <div className={css?.accountDetailsBoxInputBox}>
              {!DOBToggle ? (
                <span className={css?.accountDetailsBoxInputBoxLabel}>
                  {userObj?.birthDate?.split(" ")[0]}
                </span>
              ) : (
                <input
                  type="date"
                  value={userObj?.birthDate?.split(" ")[0]}
                  onChange={(e) =>
                    setuserObj({
                      ...userObj,
                      birthDate: e.target.value,
                    })
                  }
                  className={css?.accountDetailsBoxInputBoxInput}
                ></input>
              )}
              <span
                className={css?.accountDetailsBoxInputBoxEditIcon}
                onClick={() => setDOBToggle(!DOBToggle)}
              >
                <FaEdit />
              </span>
            </div>
          </div>
          <button className={css?.submitButton} onClick={handleUpdateProfile}>
            Save
          </button>
        </div>

        <div className={css?.accountDetailsBoxText3}>
          Note: Your profile will be permanently deleted and you will not be
          able to recover your account.
        </div>
        <button
          className={css?.submitButton}
          style={{ background: "rgb(202, 2, 2)" }}
          onClick={() => setOpenDeleteModal(true)}
        >
          Delete Profile
        </button>
        <h5 style={{ color: "red" }}>{error ? error : ""}</h5>
      </div>
    </div>
  );
};

export default UserProfile;
