import React from "react";
import Layout from "../layouts/GeneralLayout";
import Terms from "../screens/Info/Terms";

const MobileTermsPage = () => {
  return (
    <Layout>
      <Terms baseUrl="/mobile" />
    </Layout>
  );
};

export default MobileTermsPage;
