import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMyTrips } from "../../store/actions/myTripsActions";
import { useParams } from "react-router-dom";
import TripCard from "../../components/tripCard/tripCard";
import css from "../../style/myTrips.module.css";
import { CircularProgress } from "@material-ui/core";
import * as Sentry from "@sentry/react";
import { BOOKING_TYPES } from "../../store/constants/baseUrl";

const MyTrips = () => {
  //my trip object
  const [myTripsState, setmyTripsState] = useState();
  const [filter, setfilter] = useState("All");
  // dispatch redux actions
  const dispatch = useDispatch();
  // getting userId from params
  const { userId } = useParams();
  // calling my trips state
  const { myTripsInfo, myTripsLoading } = useSelector((state) => state.myTrips);
  // calling my trip API through action
  useEffect(() => {
    try {
      dispatch(getMyTrips(userId, filter));
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [filter]);
  // populating my trip state
  useEffect(() => {
    try {
      if (myTripsInfo) {
        setmyTripsState(myTripsInfo);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [myTripsInfo]);
  return (
    <div className={css?.tripsMainDiv}>
      <div className={css?.tripsLeftDiv}>
        <div className={css?.tripsHeading}>calander overview</div>
      </div>
      <div className={css?.tripsRightDiv}>
        <div className={css?.tripsTopLine}>
          <div className={css?.tripsHeading}>my trips</div>
          <select
            onChange={(e) => setfilter(e.target.value)}
            className={css?.filterDropdown}
          >
            {BOOKING_TYPES.map((type) => (
              <option value={type.value}>{type.option}</option>
            ))}
          </select>
        </div>
        <div className={css?.tripsCardsDiv}>
          {myTripsState ? (
            myTripsState?.map((trip) => <TripCard data={trip} />)
          ) : myTripsLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "40vh",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <h3>"No trip found..."</h3>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyTrips;
