import React from "react";
import Layout from "../layouts/GeneralLayout";
import ThankYou from "../screens/Booking/Thankyou";

const MobileThankyou = () => {
  return (
    <Layout>
      <ThankYou />
    </Layout>
  );
};

export default MobileThankyou;
