import {
  GET_DELIVERY_SLOTS_FAIL,
  GET_DELIVERY_SLOTS_SUCCESS,
  GET_DELIVERY_SLOTS_REQUEST,
} from "../constants/deliverySlotsConstants";
import axios from "axios";
import baseUrl from "../constants/baseUrl";
import * as Sentry from "@sentry/react";

// delivery slots action
// this action is called in delivery pages
// it shows available delivery slots
export const getAllDeliverySlots = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_DELIVERY_SLOTS_REQUEST,
    });
    const { data } = await axios.get(`${baseUrl}DeliverySlotsAPI`);
    console.log("delivery slots data from api", data);
    dispatch({
      type: GET_DELIVERY_SLOTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    Sentry.captureException(error);
    console.log(error);
    dispatch({
      type: GET_DELIVERY_SLOTS_FAIL,
      payload: "delivery slots API Failed,Please try again!",
    });
  }
};
// single vehicle action
// this action is called in multiple pages
// it shows vehicle specs
export const getSingleDeliverySlot = (deliverySlotId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_DELIVERY_SLOTS_REQUEST,
    });
    const { data } = await axios.get(
      `${baseUrl}DeliverySlotsAPI/${deliverySlotId}`
    );
    console.log("delivery slots data from api", data);
    dispatch({
      type: GET_DELIVERY_SLOTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    Sentry.captureException(error);
    console.log(error);
    dispatch({
      type: GET_DELIVERY_SLOTS_FAIL,
      payload: "delivery slots API Failed,Please try again!",
    });
  }
};
