import React from "react";
import Layout from "../layouts/GeneralLayout";
import VehicleSpecs from "../screens/Info/VehicleInfo";

const MobileVehicleInfo = () => {
  return (
    <Layout>
      <VehicleSpecs />
    </Layout>
  );
};

export default MobileVehicleInfo;
