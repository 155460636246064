import React from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  bookingIfCardSaved,
  bookingIfCardNotSaved,
} from "../../store/actions/paymentActions";
import * as Sentry from "@sentry/react";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "black",
      fontWeight: "500",
      fontFamily: "Roboto,Open Sans,Segeo UI,sans-serif",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#fce883" },
      ":-placeholder": { color: "#87bbfd" },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "red",
    },
  },
};

const PaymentForm = ({ bookingObject, submitWrapper }) => {
  // history
  const history = useHistory();
  // stripe setup
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const { error, token } = await stripe.createToken(
        elements.getElement(CardElement)
      );
      if (!error) {
        const { id } = token;
        dispatch(
          bookingIfCardNotSaved(
            bookingObject?.id,
            bookingObject?.type,
            history,
            id
          )
        );
      } else {
        console.log("payment error", error.message);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  return (
    <div>
      <form
        id="payment-in-booking"
        onSubmit={(e) =>
          submitWrapper ? submitWrapper(handleSubmit, e) : handleSubmit(e)
        }
      >
        <fieldset style={{ border: "none!important" }}>
          <div className="FormRow">
            <CardElement options={CARD_OPTIONS} />
          </div>
        </fieldset>
      </form>
    </div>
  );
};

export default PaymentForm;
