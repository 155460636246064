import React from "react";
import Layout from "../layouts/GeneralLayout";
import Settings from "../screens/Account/settings";

const MobileSettings = () => {
  return (
    <Layout>
      <Settings />
    </Layout>
  );
};

export default MobileSettings;
