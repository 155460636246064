import React from "react";
import css from "../../style/deliveryInfo.module.css";
import { useHistory } from "react-router";

const S1Banner = () => {
  const history = useHistory();
  return (
    <div className={css?.s1Md}>
      <div className={css?.s1CL}>
        <div className={css?.s1CLH}>Let us take care of the heavy lifting.</div>
        <div className={css?.s1CLT}>
          For those oversized items that won't fit in your vehicle, we've got
          you covered with our on-demand trucks and moovers.
        </div>
        <div className={css?.s1CLBtn} onClick={() => history.push("/booking")}>
          Schedule a delivery
        </div>
      </div>
      <img src="/images/delivery_info.png" alt="" className={css?.s1CRImg} />
    </div>
  );
};

export default S1Banner;
