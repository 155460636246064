import {
  GET_MOVE_TYPES_FAIL,
  GET_MOVE_TYPES_SUCCESS,
  GET_MOVE_TYPES_REQUEST,
} from "../constants/moveTypesConstants";

// used for move types
export const moveTypesReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_MOVE_TYPES_REQUEST:
      return {
        ...state,
        moveTypesInfo: null,
        moveTypesLoading: true,
      };
    case GET_MOVE_TYPES_SUCCESS:
      return {
        ...state,
        moveTypesLoading: false,
        moveTypesInfo: payload,
        moveTypesError: "",
      };
    case GET_MOVE_TYPES_FAIL:
      return {
        ...state,
        moveTypesLoading: false,
        moveTypesError: payload,
      };
    default:
      return state;
  }
};
