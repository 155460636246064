import React, { useState } from "react";
import Layout from "../layouts/GeneralLayout";
import BookingType from "../screens/Info/BookingType";
import Modal from "../components/modal";
import { useHistory } from "react-router-dom";
const cover = require("../assets/images/mover_banner.svg").default;
const MobileMoverPage = () => {
  const [showModal, setShow] = useState(false);
  const history = useHistory();
  const handleShowModal = () => setShow(!showModal);
  const openInfoPage = () => {
    history.push("moverInfo");
  };
  return (
    <>
      <Layout
        infoIcon={true}
        pageName="What is a mover job?"
        headerClickFunction={handleShowModal}
      >
        <BookingType
          baseUrl={"/mobile"}
          cover={cover}
          title="Need the muscle without the truck?"
          p1="Schedule our on-demand movers to help with all your heavy lifting needs"
          p2="Our moovers can help move any number of items within reason."
          bottomButtonText={"Book now"}
          bookingType="Moovers"
        />
      </Layout>
      <Modal
        visible={showModal}
        turnItOff={() => {
          setShow(false);
        }}
        title={"Mover guidelines"}
        p1={
          "For a positive mover experience, make sure you follow these requirements:"
        }
        list={[
          "Our moovers can lift items up to 100 lbs. but can choose not to lift heavy items in precarious situations at their discretion.",
          "Antique furniture, pianos, or other high value items will only be moved if they can be handled safely and without major obstacles.",
          "Common use cases: rental truck or C-Can load/unload, rearranging household furniture, etc.",
        ]}
        afterList={
          <>
            <h5>Prohibited items</h5>
            <p>
              Alcohol, recreational drugs, medications, firearms, and hazardous
              or illegal items.
            </p>
          </>
        }
        greenBtnText={"Got it"}
        openInfoPage={openInfoPage}
      />
    </>
  );
};

export default MobileMoverPage;
