import React from "react";
import Layout from "../layouts/GeneralLayout";
import ResetPassword from "../screens/Account/ResetPassword";

const MobileResetPasswordPage = () => {
  return (
    <Layout>
      <ResetPassword />
    </Layout>
  );
};

export default MobileResetPasswordPage;
