import React from "react";
import Layout from "../layouts/GeneralLayout";
import MyTrips from "../screens/Booking/MyTrips";

const MobileMyTripsPage = () => {
  return (
    <Layout
      style={{
        backgroundColor: "rgb(239,245,255)",
        height: "100vh",
        width: "100%",
      }}
    >
      <MyTrips />
    </Layout>
  );
};

export default MobileMyTripsPage;
