import React from "react";
// importing the css
import css from "../../style/booking.module.css";
import { BiPlusMedical } from "react-icons/bi";
import { FaMinus } from "react-icons/fa";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import LightTooltip from "../Tooltip/tooltip";

const Counter = ({ value, min, max, increment, decrement }) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  return (
    <div className={css?.counterMainDiv}>
      <div
        className={css?.Level2Heading}
        style={{ display: "flex", alignItems: "center" }}
      >
        # of movers
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <LightTooltip
            title={
              <div className={css?.tooltipMainDiv}>
                <div className={css?.tooltipBullet}>
                  <div className={css?.tooltipDotBox}>
                    <div className={css?.tooltipDot}>.</div>
                  </div>
                  <div className={css?.tooltipText}>
                    Please select the number of moovers needed for your job.
                  </div>
                </div>
              </div>
            }
            onClose={handleTooltipClose}
            open={open}
          >
            <img
              src="images/tooltip.svg"
              style={{
                display: "inline",
                width: "30px",
                cursor: "pointer",
                margin: "0px 5px",
              }}
              alt=""
              onClick={handleTooltipOpen}
            />
          </LightTooltip>
        </ClickAwayListener>
      </div>
      <div className={css?.counterStrip}>
        <div
          onClick={() => value > min && decrement()}
          style={value > min ? {} : { backgroundColor: "#9e9ac7" }}
          className={css?.counterButtons}
        >
          <FaMinus />
        </div>
        <input
          value={value}
          min={min}
          max={max}
          className={css?.counterInput}
        />

        <div
          className={css?.counterButtons}
          style={value < max ? {} : { backgroundColor: "#9e9ac7" }}
          onClick={() => value < max && increment()}
        >
          <BiPlusMedical />
        </div>
      </div>
    </div>
  );
};

export default Counter;
