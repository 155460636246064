import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
// importing the css
import css from "../../style/booking.module.css";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { HiPlusCircle, HiMinusCircle } from "react-icons/hi";
import { deleteStop } from "../../store/actions/bookingActions";
import { useDispatch } from "react-redux";
import { searchOptions } from "../../Utilities/mapUtilities";

const Places = ({ bookingObject, handleUpdateBookingObject }) => {
  // dispatch redux actions
  const dispatch = useDispatch();
  // state to edit a stop
  const [editStopToggle, seteditStopToggle] = useState({
    status: false,
    address: "",
    index: "",
  });

  const [pickupLocation, setPickupLocation] = useState("");
  const [dropoffLocation, setDropoffLocation] = useState("");
  // current stop text
  const [currentStop, setcurrentStop] = useState("");
  // state to add a stop
  const [AddStopToggle, setAddStopToggle] = useState(false);

  // edit stop
  const handleEditStop = (address, index) => {
    try {
      setAddStopToggle(false);
      seteditStopToggle({ status: true, address: address, index: index });
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const handleEditStopSuccessfully = async (e) => {
    try {
      seteditStopToggle(false);
      const newStopsArray = bookingObject?.stops;
      const results = await geocodeByAddress(e);
      const latLng = await getLatLng(results[0]);
      newStopsArray[editStopToggle?.index] = {
        ...newStopsArray[editStopToggle?.index],
        stop: e,
        lat: latLng?.lat,
        long: latLng?.lng,
      };

      handleUpdateBookingObject("stops", newStopsArray, "14.1");
      seteditStopToggle({ status: false, address: "", index: null });
      window.location.reload(false);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };
  const handleAddStop = async (e) => {
    try {
      if (e) {
        const results = await geocodeByAddress(e);
        const latLng = await getLatLng(results[0]);
        const newStop = {
          id: 0,
          stop: e,
          lat: latLng?.lat,
          long: latLng?.lng,
          personName: "",
          personPhone: "",
          instructions: "",
        };
        if (
          bookingObject?.stops &&
          Array.isArray(bookingObject?.stops) &&
          bookingObject?.stops?.length > 0
        ) {
          handleUpdateBookingObject(
            "stops",
            [...bookingObject?.stops, newStop],
            "14.2"
          );
        } else {
          handleUpdateBookingObject("stops", [newStop], "14.3");
        }
      }
      setcurrentStop("");
      setAddStopToggle(false);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };
  const handleDeleteStop = (index) => {
    try {
      const stopToDelete = bookingObject?.stops?.filter(
        (address, addIndex) => addIndex === index
      );

      handleUpdateBookingObject(
        "stops",
        bookingObject?.stops?.filter((address, addIndex) => addIndex !== index),
        "14.4"
      );
      if (stopToDelete?.length > 0) {
        dispatch(deleteStop(stopToDelete[0]?.id));
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const returnPlaceholderForPickup = () => {
    try {
      return bookingObject.type === "Moovers"
        ? "Address #1"
        : "Enter pickup location";
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const returnPlaceholderForDropoff = () => {
    try {
      if (bookingObject.type !== "Moovers") return "Enter destination";
      if (bookingObject?.stops?.length && AddStopToggle)
        return `Address #${bookingObject.stops.length + 3}`;
      if (bookingObject?.stops?.length)
        return `Address #${bookingObject.stops.length + 2}`;
      if (AddStopToggle) return `Address #3`;
      return `Address #2`;
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const returnPlaceholderForStop = () => {
    try {
      if (bookingObject.type !== "Moovers") return "Enter additional location";
      if (bookingObject?.stops?.length)
        return `Address #${bookingObject.stops.length + 2}`;
      return `Address #2`;
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  useEffect(() => {
    try {
      setPickupLocation(bookingObject.pickupLocation);
      setDropoffLocation(bookingObject.dropoffLocation);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [bookingObject]);
  return (
    <div className={css?.mapPlacesMainDiv}>
      {/* 
        Places AUTO COMP START
        */}

      {/* PlacesAuto */}
      {/* A React component to build a customized UI for Google Maps Places Autocomplete */}
      {/* pickup address */}
      <div
        className={css?.Level1Heading}
        style={{ color: "var(--primaryDark)" }}
      >
        Trip Details
      </div>
      <div className={css?.placesMainDiv}>
        {bookingObject.type == "Moovers" &&
        !(bookingObject.stops.length || AddStopToggle) ? (
          <></>
        ) : (
          <div
            className={css?.dottedLine}
            style={{
              height: `calc(${bookingObject.type == "Moovers" ? 0 : 75}px + ${
                bookingObject.stops.length ? bookingObject.stops.length * 75 : 0
              }px + ${AddStopToggle ? 75 : 0}px)`,
            }}
            // style={{ height: 120 }}
          >
            .
          </div>
        )}
        <PlacesAutocomplete
          searchOptions={searchOptions}
          value={pickupLocation}
          onChange={(e) => {
            try {
              if (!e) {
                handleUpdateBookingObject("pickupLocation", "", "14.5");
              }
              setPickupLocation(e);
            } catch (error) {
              Sentry.captureException(error);
              console.log(error);
            }
          }}
          onSelect={(e) => {
            try {
              handleUpdateBookingObject("pickupLocation", e, "14.6");
              setPickupLocation(e);
            } catch (error) {
              Sentry.captureException(error);
              console.log(error);
            }
          }}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div className={css?.PlacesAutocomplete} style={{ paddingTop: 0 }}>
              <div className={css?.inputLine}>
                <div className={css?.PickupOverlapingDiv}>
                  {bookingObject.type == "Moovers" &&
                  !bookingObject.stops.length &&
                  !AddStopToggle ? (
                    <></>
                  ) : (
                    <div className={css?.PlacesCircle}>.</div>
                  )}
                </div>
                <input
                  className={css?.pickupInputTag}
                  {...getInputProps({
                    placeholder: returnPlaceholderForPickup(),
                  })}
                />
              </div>

              <div className={css?.suggestionsDiv}>
                {loading ? (
                  <div className={css?.suggestionsLine}>...loading</div>
                ) : null}

                {suggestions.map((suggestion) => {
                  const style = {
                    backgroundColor: suggestion.active
                      ? "var(--secondary)"
                      : "white",
                  };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        style,
                      })}
                      className={css?.suggestionsLine}
                    >
                      {suggestion?.description}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
        {/* stops to cover */}
        {bookingObject?.stops?.map((address, index) => (
          <div key={index} className={css?.PlacesAutocomplete}>
            <div className={css?.inputLine}>
              <div className={css?.StopOverlapingDiv}>
                <div
                  className={css?.StopsCircle}
                  style={{ borderRadius: "0%" }}
                >
                  .
                </div>
              </div>
              <div className={css?.stopText}>{address?.stop}</div>
              <div
                onClick={() => handleDeleteStop(index)}
                className={css?.stopDeleteButton}
              >
                Remove
              </div>
            </div>
          </div>
        ))}
        {AddStopToggle && (
          <PlacesAutocomplete
            searchOptions={searchOptions}
            value={currentStop}
            onChange={(e) => {
              try {
                setcurrentStop(e);
              } catch (error) {
                Sentry.captureException(error);
                console.log(error);
              }
            }}
            id="placesAutocompleteDropOff"
            onSelect={handleAddStop}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div className={css?.PlacesAutocomplete}>
                <div className={css?.inputLine}>
                  <div className={css?.StopOverlapingDiv}>
                    <div
                      className={css?.StopsCircle}
                      style={{ borderRadius: "0%" }}
                    >
                      .
                    </div>
                  </div>
                  <input
                    className={css?.pickupInputTag}
                    {...getInputProps({
                      placeholder: returnPlaceholderForStop(),
                    })}
                  />
                </div>
                <div className={css?.suggestionsDiv}>
                  {loading ? (
                    <div className={css?.suggestionsLine}>...loading</div>
                  ) : null}

                  {suggestions.map((suggestion) => {
                    const style = {
                      backgroundColor: suggestion.active
                        ? "var(--secondary)"
                        : "white",
                    };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          style,
                        })}
                        className={css?.suggestionsLine}
                      >
                        {suggestion.description}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        )}
        {/* drop off address */}
        {bookingObject.type == "Moovers" ? (
          <></>
        ) : (
          <PlacesAutocomplete
            searchOptions={searchOptions}
            value={dropoffLocation}
            onChange={(e) => {
              try {
                if (!e) {
                  handleUpdateBookingObject("dropoffLocation", "", "14.7");
                }
                setDropoffLocation(e);
              } catch (error) {
                Sentry.captureException(error);
                console.log(error);
              }
            }}
            id="placesAutocompleteDropOff"
            onSelect={(e) => {
              try {
                handleUpdateBookingObject("dropoffLocation", e, "14.8");
                setDropoffLocation(e);
              } catch (error) {
                Sentry.captureException(error);
                console.log(error);
              }
            }}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div
                className={css?.PlacesAutocomplete}
                style={{ borderBottom: "none", paddingBottom: 0 }}
              >
                <div className={css?.inputLine}>
                  <div className={css?.DropoffOverlapingDiv}>
                    <div
                      className={css?.PlacesCircle}
                      style={{ borderRadius: "0px" }}
                    >
                      .
                    </div>
                  </div>
                  <input
                    className={css?.pickupInputTag}
                    {...getInputProps({
                      placeholder: returnPlaceholderForDropoff(),
                    })}
                  />
                </div>
                <div className={css?.suggestionsDiv}>
                  {loading ? (
                    <div className={css?.suggestionsLine}>...loading</div>
                  ) : null}

                  {suggestions.map((suggestion) => {
                    const style = {
                      backgroundColor: suggestion.active
                        ? "var(--secondary)"
                        : "white",
                    };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          style,
                        })}
                        className={css?.suggestionsLine}
                      >
                        {suggestion.description}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        )}
      </div>

      {/* <div className={css?.ClearButton} onClick={EnablingClear}>
            Clear
      </div> */}

      <div className={css?.addAStopButtonDiv}>
        <button
          onClick={() => {
            try {
              setAddStopToggle(!AddStopToggle);
              seteditStopToggle({
                address: "",
                index: "",
                status: false,
              });
            } catch (error) {
              Sentry.captureException(error);
              console.log(error);
            }
          }}
          className={css?.stopBtn}
        >
          <span className={css?.stopPlusIcon}>
            {AddStopToggle ? <HiMinusCircle /> : <HiPlusCircle />}
          </span>
          {AddStopToggle ? "Remove" : "Add a"} stop
        </button>
      </div>

      {editStopToggle?.status && (
        <PlacesAutocomplete
          searchOptions={searchOptions}
          value={editStopToggle?.address}
          onChange={(e) => {
            try {
              seteditStopToggle({ ...editStopToggle, address: e });
            } catch (error) {
              Sentry.captureException(error);
              console.log(error);
            }
          }}
          id="placesAutocompleteDropOff"
          onSelect={handleEditStopSuccessfully}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div className={css?.PlacesAutocomplete2}>
              <div className={css?.inputLine}>
                <i
                  class="fas fa-map-marker-alt"
                  style={{ margin: "auto" }}
                  aria-hidden="true"
                ></i>
                <input
                  className={css?.pickupInputTag}
                  {...getInputProps({
                    placeholder: "Enter additional location",
                  })}
                />
              </div>
              <div className={css?.suggestionsDiv}>
                {loading ? (
                  <div className={css?.suggestionsLine}>...loading</div>
                ) : null}

                {suggestions.map((suggestion) => {
                  const style = {
                    backgroundColor: suggestion.active
                      ? "var(--secondary)"
                      : "white",
                  };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        style,
                      })}
                      className={css?.suggestionsLine}
                    >
                      {suggestion.description}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      )}

      {/* 
        PLACES AUTO COMP END
         */}
    </div>
  );
};

export default Places;
