import React from "react";
import Index from "../screens/Info/Index";
import Layout from "../layouts/GeneralLayout";

const MobileIndex = () => {
  return (
    <Layout showBackBtn={false}>
      <Index />
    </Layout>
  );
};

export default MobileIndex;
