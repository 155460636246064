import Fade from "@material-ui/core/Fade";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RoundButton from "../../components/Booking/RoundButton";
import { ContactUsMessage } from "../../store/actions/ContactActions";
import css from "../../style/loginStyles.module.css";
function MoovezContactUs() {
  const { loginInfo } = useSelector((state) => state.loginReducer);

  useEffect(() => {
    try {
      if (loginInfo?.id) {
        setEmail(loginInfo.email);
        setName(loginInfo.firstName + " " + loginInfo.lastName);
        setcontactObj({
          ...contactObj,
          UserId: loginInfo?.id,
        });
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [loginInfo]);
  //contact object
  const [contactObj, setcontactObj] = useState({
    id: 0,
    Name: "",
    Email: "",
    Message: "",
    UserId: "",
  });

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  // dispatch actions
  const dispatch = useDispatch();
  // email
  const [email, setEmail] = useState("");
  // name
  const [name, setName] = useState("");
  // complain
  const [message, setMessage] = useState("");

  const [error, setError] = useState("");
  const [status, setStatus] = useState("");
  // Sign In
  const handleSubmitComplain = () => {
    try {
      if (contactObj) {
        if (name) {
          if (email) {
            if (message) {
              dispatch(
                ContactUsMessage(
                  { ...contactObj, Email: email, Name: name, Message: message },
                  setStatus,
                  setError
                )
              ).then((data) => {
                console.log("response from contact us", data);
                if (data.statusCode === 200) {
                  setOpen(true);
                }
              });
            } else {
              alert("please enter messsage");
            }
          } else {
            alert("please enter email");
          }
        } else {
          alert("please enter name");
        }
      } else {
        alert("Please fill all the fields");
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  //Modal styles
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  return (
    <div className={css?.loginMainDiv}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Thank you for contacting moovez. Your concern/Feedback has been sent
            to admin.
          </Typography>

          <div style={{ marginLeft: "12%" }}>
            <RoundButton
              text="Ok"
              clickFunction={() => {
                setOpen(false);
                if (!loginInfo.email) {
                  setEmail("");
                  setName("");
                  setMessage("");
                }
              }}
            />
          </div>
          {/* {loader ?<div style={{textAlignLast: 'center', paddingTop:'10%'}}> 
        <Box >
        <CircularProgress color="secondary"/>
        </Box>
        </div> : <div></div>} */}
        </Box>
      </Modal>
      <img src="/images/Moovez-Dark.svg" alt="" className={css?.signupImage} />
      <div className={css?.posterMainHeading}>Contact Us</div>
      {/* <div className={css?.posterText}>Let’s get your stuff mooved.</div> */}
      {!loginInfo?.email ? (
        <>
          <input
            value={name}
            placeholder="Name"
            onChange={(e) => setName(e.target.value)}
            className={css?.fullInput}
          ></input>
          <input
            value={email}
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            className={css?.fullInput}
          ></input>
        </>
      ) : (
        <></>
      )}
      <textarea
        value={message}
        placeholder="Message"
        onChange={(e) => setMessage(e.target.value)}
        className={css?.fullInput}
      ></textarea>

      {error ? (
        <div className={css?.signinRedirectError}>
          <Fade in={error === true} timeout={1000}>
            <p>{error}</p>
          </Fade>
        </div>
      ) : (
        <div></div>
      )}

      <div className={css?.signupBtnMainDiv}>
        <button className={css?.signupBtn} onClick={handleSubmitComplain}>
          Submit
        </button>
      </div>

      <div className={css?.signinRedirect}>
        Please feel free to email us with any questions or concerns or call
        780-243-2186. The moovez team is here for you...
      </div>
    </div>
  );
}

export default MoovezContactUs;
