import React, { useState, useRef, useEffect } from "react";
import css from "../../style/booking.module.css";
import { useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { FaChevronDown } from "react-icons/fa";

const BookingType = ({ otherTypes, handleUpdateBookingObject }) => {
  const history = useHistory();
  const [toggleDD, setToggleDD] = useState(false);
  const menuRef = useRef();
  const bookingTypeFromLS = localStorage.getItem("currentBookingType");
  const names = {
    Delivery: "Delivery",
    Mooving: "Moving",
    Moovers: "Movers",
    Business: "Business",
  };
  const changeFunc = (val) => {
    try {
      if (val && val !== bookingTypeFromLS) {
        localStorage.removeItem(`wemoovez${bookingTypeFromLS}Object`);
        if (["Delivery", "Mooving", "Moovers"].includes(val)) {
          handleUpdateBookingObject("type", val, "12");
        } else if (val === "Business") history.push("/business");
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  useEffect(() => {
    const closeDDMenuEvent = (e) => {
      if (!menuRef?.current?.contains(e.target)) {
        setToggleDD(false);
      }
    };
    document.addEventListener("click", closeDDMenuEvent);

    return () => {
      document.addEventListener("click", closeDDMenuEvent);
    };
  }, []);
  return (
    <div className={css?.dropdownMainDiv}>
      <div
        className={css?.dropdownTextDiv}
        onClick={() => setToggleDD(!toggleDD)}
        ref={menuRef}
      >
        {names[bookingTypeFromLS]
          ? names[bookingTypeFromLS]
          : "Select booking type"}
        <FaChevronDown />
      </div>
      <div
        className={css?.DDOptions}
        style={{ display: toggleDD ? "block" : "none" }}
      >
        {otherTypes?.map((booking, index) => (
          <div
            key={index + index}
            onClick={() => changeFunc(booking)}
            className={css?.DDOption}
            style={{
              backgroundColor:
                booking === bookingTypeFromLS ? "var(--secondary)" : "white",
            }}
          >
            {names[booking]}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BookingType;
