import axios from "axios";
import baseUrl from "../constants/baseUrl";
import * as Sentry from "@sentry/react";
import {
  GET_PAYMENT_METHOD_FAIL,
  GET_PAYMENT_METHOD_SUCCESS,
  GET_PAYMENT_METHOD_REQUEST,
} from "../constants/paymentMethodConstants";

export const bookingIfCardSaved =
  (bookingId, type, history) => async (dispatch) => {
    try {
      // const { data } = await axios.get(
      //   `${baseUrl}BookingsAPI/holdPayment?bookingId=${bookingId}&amount=
      //   ${Math.round(
      //     parseInt(amount)
      //   )}
      //   &currency=cad`
      // );
      const { data } = await axios.get(
        `${baseUrl}BookingsAPI/holdPayment?bookingId=${bookingId}&currency=cad`
      );
      console.log("charge id", data);
      await axios.get(
        `${baseUrl}BookingsAPI/chargePayment?chargeId=${data?.riderPaymentsTripChargeId}`
      );
      localStorage.removeItem(`wemoovez${type}Object`);
      history.push(`/thankyou`);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

export const bookingIfCardNotSaved =
  (bookingId, type, history, token) => async (dispatch) => {
    try {
      const { data } = await axios.get(
        `${baseUrl}BookingsAPI/holdPayment?bookingId=${bookingId}&currency=cad&stripeToken=${token}`
      );
      await axios.get(
        `${baseUrl}BookingsAPI/chargePayment?chargeId=${data?.riderPaymentsTripChargeId}`
      );
      localStorage.removeItem(`wemoovez${type}Object`);
      history.push(`/thankyou`);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

// user payment methods action
// this action is called in user profile pages
export const userPaymentMethods = (userId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PAYMENT_METHOD_REQUEST,
    });
    console.log("userID from FE", userId);
    const { data } = await axios.get(
      `${baseUrl}ProfileDetailAPI/UserPaymentMethods?id=${userId}`
    );
    console.log("user payment methods from api", data);
    dispatch({
      type: GET_PAYMENT_METHOD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    Sentry.captureException(error);
    console.log(error);
    dispatch({
      type: GET_PAYMENT_METHOD_FAIL,
      payload: error,
    });
  }
};

// set default payment methods action
// this action is called in user profile pages
export const setDefaultPaymentMethod = (userId, cardId) => async (dispatch) => {
  try {
    console.log("cardId from FE", cardId);
    const res = await axios.get(
      `${baseUrl}ProfileDetailAPI/SetDefaultPaymentMethod?cardId=${cardId}`
    );
    console.log("user payment methods from api", res?.data);
    const { data } = await axios.get(
      `${baseUrl}ProfileDetailAPI/UserPaymentMethods?id=${userId}`
    );
    console.log("user payment methods from api", data);
    dispatch({
      type: GET_PAYMENT_METHOD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    Sentry.captureException(error);
    console.log(error);
  }
};

// delete payment methods action
// this action is called in user profile pages
export const deletePaymentMethod = (userId, cardId) => async (dispatch) => {
  try {
    console.log("cardId from FE", cardId);
    const res = await axios.get(
      `${baseUrl}ProfileDetailAPI/RemovePaymentMethod?cardId=${cardId}`
    );
    console.log("user payment methods from api", res?.data);
    const { data } = await axios.get(
      `${baseUrl}ProfileDetailAPI/UserPaymentMethods?id=${userId}`
    );
    console.log("user payment methods from api", data);
    dispatch({
      type: GET_PAYMENT_METHOD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    Sentry.captureException(error);
    console.log(error);
  }
};
