import React, { useState } from "react";
import css from "../../style/registerPageStyle.module.css";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { referralSignup } from "../../store/actions/friendReferralActions";
import {
  FaCheckSquare,
  FaEnvelope,
  FaMobileAlt,
  FaSquare,
  FaTimesCircle,
  FaUnlockAlt,
  FaUserEdit,
} from "react-icons/fa";
import * as Sentry from "@sentry/react";
import { handleValidatePhoneNumber } from "../../Utilities/general";

function Register() {
  // error
  const [error, seterror] = useState("");
  //history for routing
  const history = useHistory();
  // dispatch actions
  const dispatch = useDispatch();
  //firstName
  const [firstName, setfirstName] = useState("");
  const [firstNameActive, setfirstNameActive] = useState(false);
  //last name
  const [lastName, setlastName] = useState("");
  const [lastNameActive, setlastNameActive] = useState(false);
  //email
  const [email, setemail] = useState("");
  const [emailActive, setemailActive] = useState(false);
  //phoneNo
  const [phoneNo, setphoneNo] = useState("");
  const [phoneNoActive, setphoneNoActive] = useState(false);
  //password
  const [password, setpassword] = useState("");
  const [passwordStars, setpasswordStars] = useState("");
  const [passwordActive, setpasswordActive] = useState(false);
  const [passwordStrength, setpasswordStrength] = useState(0);
  //confirmPass
  const [confirmPassword, setconfirmPassword] = useState("");
  // terms and conditions
  const [TnC, setTnC] = useState(false);
  // getting referral code from params
  const { code } = useParams();
  // check password strength
  const handleSetPassword = (e) => {
    try {
      let strengthLevel = 0;
      const passArray = [...e];
      setpassword(e);
      if (e !== 0) {
        setpasswordStars(passArray?.map((l) => "*"));

        const hasLowerCase = passArray?.filter(
          (letter) => letter >= "a" && letter <= "z"
        );
        const hasUpperCase = passArray?.filter(
          (letter) => letter >= "A" && letter <= "Z"
        );
        const hasNumber = passArray?.filter(
          (letter) => letter >= "0" && letter <= "9"
        );
        const hasSC1 = passArray?.filter(
          (letter) => letter >= "!" && letter <= "/"
        );
        const hasSC2 = passArray?.filter(
          (letter) => letter >= ":" && letter <= "@"
        );
        const hasSC3 = passArray?.filter(
          (letter) => letter >= "[" && letter <= "`"
        );
        const hasSC4 = passArray?.filter(
          (letter) => letter >= "{" && letter <= "~"
        );
        if (hasLowerCase?.length > 0) {
          strengthLevel = strengthLevel + 20;
        }
        if (hasUpperCase?.length > 0) {
          strengthLevel = strengthLevel + 20;
        }
        if (hasNumber?.length > 0) {
          strengthLevel = strengthLevel + 20;
        }
        if (
          hasSC1?.length > 0 ||
          hasSC2?.length > 0 ||
          hasSC3?.length > 0 ||
          hasSC4?.length > 0
        ) {
          strengthLevel = strengthLevel + 20;
        }
        if (e?.length > 5) {
          strengthLevel = strengthLevel + 20;
        }
      }
      setpasswordStrength(strengthLevel);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  function ValidateEmail(mail) {
    try {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return true;
      }
      alert("You have entered an invalid email address!");
      return false;
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }

  const handleSignUp = () => {
    try {
      if (TnC) {
        if (
          firstName &&
          lastName &&
          email &&
          phoneNo &&
          password &&
          confirmPassword
        ) {
          if (ValidateEmail(email)) {
            if (passwordStrength === 100) {
              if (password === confirmPassword) {
                if (phoneNo?.length === 12) {
                  const signupData = {
                    email: email,
                    firstName: firstName,
                    lastName: lastName,
                    password: password,
                    phone: phoneNo,
                  };
                  console.log("signup data from signup component", signupData);
                  dispatch(referralSignup(signupData, code, seterror, history));
                } else {
                  alert("Invalid phone number");
                }
              } else {
                alert("Passwords do not match");
              }
            } else {
              alert(
                "Password must be of at least 6 characters, with both upper and lower chase letters,numbers and special characters."
              );
            }
          }
        } else {
          alert("Please fill all the fields");
        }
      } else {
        alert("Please agree to the terms and conditions before proceeding");
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  return (
    <div className={css?.RegisterMainDiv}>
      <div className={css?.signup_page}>
        <div className={css?.inner_content}>
          <div class={css?.RegisterMainHeading}>Sign Up</div>
          <div className={css?.friendsReferralsText}>
            {/* Welcome to wemoovez! */}
            You will each receive a $5 credit when you place your first order.
            {/* <br /> You and your friend can use that credit in your future
              bookings. */}
          </div>
          <input
            type="button"
            className={css?.loginButton}
            value="Already have an account?"
            onClick={() => history.push("/Login")}
          />
          <div className={css?.dash_with_title}>
            <span className={css?.createAccountTxt}>Or create an account</span>
          </div>
          <>
            <div class={css?.sign_up_form}>
              <div class={css?.halfInputleft}>
                {/* <div class="input_section"> */}
                <span className={css?.textIcons}>
                  <FaUserEdit />
                </span>
                <input
                  placeholder="First name"
                  className={css?.inputField}
                  value={firstName}
                  onFocus={() => setfirstNameActive(true)}
                  onChange={(e) => setfirstName(e?.target?.value)}
                />
                {firstNameActive && !firstName && (
                  <span className={css?.crossIcon}>
                    <FaTimesCircle />
                  </span>
                )}
              </div>
              <div class={css?.halfInputleft}>
                {/* <div class="input_section"> */}
                <span className={css?.textIcons}>
                  <FaUserEdit />
                </span>
                <input
                  placeholder="Last name"
                  className={css?.inputField}
                  value={lastName}
                  onFocus={() => setlastNameActive(true)}
                  onChange={(e) => setlastName(e?.target?.value)}
                />
                {lastNameActive && !lastName && (
                  <span className={css?.crossIcon}>
                    <FaTimesCircle />
                  </span>
                )}
              </div>
              <div class={css?.fullInputleft}>
                <span className={css?.textIconsFull}>
                  <FaEnvelope />
                </span>
                <input
                  placeholder="Email"
                  value={email}
                  type="email"
                  className={css?.inputFieldFull}
                  onFocus={() => setemailActive(true)}
                  onChange={(e) => setemail(e?.target?.value)}
                />
                {emailActive && !email && (
                  <span className={css?.crossIconFull}>
                    <FaTimesCircle />
                  </span>
                )}
              </div>
              {/* phone input  */}
              <div class={css?.fullInputleft}>
                <span className={css?.textIconsFull}>
                  <FaMobileAlt />
                </span>
                <input
                  placeholder="Phone"
                  value={phoneNo}
                  className={css?.inputFieldFull}
                  onFocus={() => setphoneNoActive(true)}
                  onChange={(e) => handleValidatePhoneNumber(e, setphoneNo)}
                />
                {phoneNoActive && !phoneNo && (
                  <span className={css?.crossIconFull}>
                    <FaTimesCircle />
                  </span>
                )}
              </div>
              {/* password input  */}
              <div class={css?.fullInputleft}>
                <span className={css?.textIconsFull}>
                  <FaUnlockAlt />
                </span>
                <input
                  placeholder="Password"
                  value={password}
                  className={css?.inputFieldFull}
                  type="password"
                  onFocus={() => setpasswordActive(true)}
                  onChange={(e) => handleSetPassword(e?.target?.value)}
                />
              </div>
              <div class={css?.fullInputleft}>
                <span className={css?.textIconsFull}>
                  <FaUnlockAlt />
                </span>
                <input
                  placeholder="Confirm password"
                  value={confirmPassword}
                  type="password"
                  className={css?.inputFieldFull}
                  onChange={(e) => setconfirmPassword(e?.target?.value)}
                />
              </div>
            </div>
            <div style={{ color: "red" }}>{error ? error : ""}</div>
            <div className={css?.BottomButtons}>
              <span class={css?.checkbox_section}>
                <span
                  onClick={() => setTnC(!TnC)}
                  style={{
                    color: TnC ? "var(--primaryDark)" : "saddlebrown",
                    marginRight: "3px",
                  }}
                >
                  {TnC ? <FaCheckSquare /> : <FaSquare />}
                </span>
                <a className={css?.MEZTnC}>
                  I agree to MUVEZ’s terms of service
                </a>
              </span>
              <button onClick={handleSignUp} className={css?.submitButton}>
                Create accountz
              </button>
            </div>
          </>
        </div>
      </div>
    </div>
  );
}

export default Register;
