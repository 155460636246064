import React from "react";
import Layout from "../layouts/GeneralLayout";
import ReferAFriend from "../screens/Account/ReferAFriend";

const MobileReferAFriend = () => {
  return (
    <Layout>
      <ReferAFriend />
    </Layout>
  );
};

export default MobileReferAFriend;
