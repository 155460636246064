import React from "react";
import Layout from "../layouts/GeneralLayout";
import ContactUs from "../screens/Info/ContactUs";

const MobileContactUsPage = () => {
  return (
    <Layout>
      <ContactUs />
    </Layout>
  );
};

export default MobileContactUsPage;
